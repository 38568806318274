import React, {Component} from 'react';
import AddSession from "./AddSession";
import {ApiFetch} from "../common/ApiFetch";
import {confirmAlert} from "react-confirm-alert";
import Flatpickr from "react-flatpickr";
import {formatDate, formatHour} from "./Utils";
import FullCalendar from "@fullcalendar/react";
import GLightbox from 'glightbox';
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {API_URL} from "../common/Urls";

const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;
const lightbox = GLightbox({
    width: '100%',
    height: '100%',
    preload: true,
    draggable: false,
    keyboardNavigation: false,
    touchNavigation: false,
    onOpen: function() {
        let gslider = document.getElementById('glightbox-slider');
        if (gslider) {
            gslider.addEventListener('click', function(){
                lightbox.close();
            });
        }
    }
});

class TutorSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetched_tutors: [],
            get_all_tutors: false,
            grades: [],
            selected_date: "",
            subjects: [],
            time1: "12:00",
            time2: "12:00",
            time3: "12:00",
            time4: "12:00",
            tutor_id: 0
        }

        this.calendarComponentRef = React.createRef();
        this.days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
        this.url_params = window.location.search ? new URLSearchParams(window.location.search) : null;
        this.student_id = null;
        this.is_mini = false;
        if (this.url_params) {
            this.student_id = this.url_params.get('id');
            this.is_mini = this.url_params.get('mini') ? parseInt(this.url_params.get('mini')) > 0 : false;
        }

        this.overviewRedirect = this.overviewRedirect.bind(this);
        this.fetchTutors = this.fetchTutors.bind(this);
        this.calculatePreferredDateTime = this.calculatePreferredDateTime.bind(this);
        this.addSession = this.addSession.bind(this);
    }

    componentDidMount() {
        let self = this;

        ApiFetch('grade?limit=20&min_grade=-1', 'GET').then(function(response) {
            if (response && response.results) {
                self.setState({
                    grades: response.results
                });
            }
        });
        ApiFetch('lms/tutoring-subjects?limit=200&ordering=name', 'GET').then(function(response) {
            let subjects = [];
            if (response && response.results) {
                for (let sub of response.results) {
                    if (sub['miniassessment'] || sub['display_members']) {
                        subjects.push(sub);
                    }
                }
            }
            self.setState({
                subjects: subjects
            });
        });

        if (self.is_mini) {
            document.querySelectorAll('.tutor-select option.members').forEach(e => e.style.display = 'none');
            document.querySelectorAll('.tutor-select option.mini').forEach(e => e.style.display = 'block');
        } else {
            document.querySelectorAll('.tutor-select option.mini').forEach(e => e.style.display = 'none');
            document.querySelectorAll('.tutor-select option.members').forEach(e => e.style.display = 'block');
        }

        document.querySelectorAll('.to-step2').forEach(el => el.addEventListener('click', function() {
            document.querySelectorAll('.step2 .continue-btn').forEach(e => e.classList.add('disabled'));
            document.getElementById('tutor-list').innerHTML = '';
            document.querySelector('.show-more').style.display = 'block';
            self.setState({
                fetched_tutors: [],
                get_all_tutors: false
            }, () => {
                if (self.fetchTutors()) {
                    document.querySelector('#tutoring .step1').style.display = 'none';
                    document.querySelector('#tutoring .step2').style.display = 'flex';
                    document.querySelectorAll('#tutoring .step2 .loaded').forEach(e => e.style.display = 'none');
                    document.querySelectorAll('#tutoring .step2 .loaded .tm-loading').forEach(e => e.style.display = 'none');
                    document.querySelectorAll('#tutoring .step2 .loading .tm-loading').forEach(e => e.style.display = 'block');
                    document.querySelectorAll('#tutoring .step2 .loading').forEach(e => e.style.display = 'block');
                    document.querySelector('#tutoring #back-to-step1').classList.remove('current');
                    document.querySelector('#tutoring #back-to-step1').classList.add('past-step');
                    document.querySelector('#tutoring #back-to-step2').classList.add('current');
                    window.scrollTo({top: 0, behavior: "smooth"});
                }
            });
        }));

        document.querySelector('.show-more').addEventListener('click', function(e) {
            e.preventDefault();
            if (self.fetchTutors()) {
                document.querySelectorAll('#tutoring .step2 .loaded .tm-loading').forEach(e => e.style.display = 'block');
            }
        });

        document.getElementById('tutor-list').addEventListener('click', function(event) {
            let profile = event.target.closest('.tutor-profile');
            if (profile) {
                document.querySelectorAll('#tutor-list .tutor-profile').forEach(e => e.classList.remove('selected'));
                profile.classList.add('selected');
                document.querySelectorAll('.to-step3').forEach(e => e.classList.remove('disabled'));
            }
        });

        document.querySelectorAll('.to-step3').forEach(el => el.addEventListener('click', function() {
            if (el.classList.contains('disabled')) {
                return false;
            }

            document.querySelector('#tutoring .step2').style.display = 'none';
            document.querySelector('#tutoring .step3').style.display = 'flex';
            document.querySelector('#tutoring #back-to-step2').classList.remove('current');
            document.querySelector('#tutoring #back-to-step2').classList.add('past-step');
            document.querySelector('#tutoring #back-to-step3').classList.add('current');
            window.scrollTo({top: 0, behavior: "smooth"});

            let selected = document.querySelector('#tutor-list .tutor-profile.selected');
            self.setState({
                tutor_id: selected.dataset.id
            });
        }));

        document.getElementById('back-to-step1').addEventListener('click', function() {
            if (this.classList.contains('past-step')) {
                document.querySelector('#tutoring .step1').style.display = 'flex';
                document.querySelector('#tutoring .step2').style.display = 'none';
                document.querySelector('#tutoring .step3').style.display = 'none';

                document.querySelectorAll('#tutoring .steps-visual > div').forEach(e => e.classList.remove('current'));
                document.querySelector('#tutoring #back-to-step1').classList.add('current');
            }
        });

        document.getElementById('back-to-step2').addEventListener('click', function() {
            if (this.classList.contains('past-step')) {
                document.querySelector('#tutoring .step2').style.display = 'flex';
                document.querySelector('#tutoring .step1').style.display = 'none';
                document.querySelector('#tutoring .step3').style.display = 'none';

                document.querySelectorAll('#tutoring .steps-visual > div').forEach(e => e.classList.remove('current'));
                document.querySelector('#tutoring #back-to-step2').classList.add('current');
            }
        });

        if (self.student_id) {
            document.querySelector('#tutoring input[name=student_id]').value = self.student_id;
            ApiFetch('lms/student-info/?student_id=' + self.student_id, 'GET').then(function(results) {
                let body = document.getElementById('tutoring');
                body.querySelector('input[name=name]').value = results.name;
                body.querySelector('select[name=grade]').value = results.grade;
                if (results.subjects.length) {
                    body.querySelector('select[name=subject1]').value = results.subjects[0];
                    if (results.subjects.length > 1) {
                        body.querySelector('select[name=subject2]').value = results.subjects[1];
                    }
                }
                body.querySelectorAll('.tm-loading').forEach(e => e.style.display = 'none');
                body.querySelector('.tutor-select').style.display = 'block';
                body.querySelector('input[name=get_ahead]').value = (results.family.get_ahead) ? 1 : 0;
            });
        } else {
            document.querySelectorAll('#tutoring .tm-loading').forEach(e => e.style.display = 'none');
            document.querySelector('#tutoring .tutor-select').style.display = 'block';
        }

        if (self.is_mini) {
            document.querySelector('#tutoring .regular-session').style.display = 'none';
            document.querySelector('#tutoring #back-to-step2 .step-title').innerHTML = 'Get Matched with a Tutor';
            document.querySelector('#add-edit-session .session-length').style.display = 'none';
            document.querySelectorAll('#tutoring .mini-only').forEach(e => e.style.display = 'block');
        }

        let shortTz = new Date().toLocaleString('en-us', {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, timeZoneName: "shortGeneric"}).split(" ")[3];
        if (shortTz) {
            document.querySelectorAll('#tutoring .schedule-preference .time-container p > span').forEach(e => e.innerHTML = shortTz);
        }
    }

    overviewRedirect() {
        let self = this;
        if (self.is_mini) {
            window.location.href = '/tutoring/?scheduled=1&mini=1';
        } else {
            window.location.href = '/tutoring/?scheduled=1';
        }
    }

    fetchTutors() {
        let self = this;
        if (!document.querySelector('input[name=name]').value) {
            confirmAlert({
                message: "Please enter your child's name.",
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return false;
        }
        if (document.querySelector('select[name=grade]').value === "0") {
            confirmAlert({
                message: "Please select a grade.",
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return false;
        }
        if (document.querySelector('select[name=subject1]').value === "0") {
            confirmAlert({
                message: "Please select a primary subject.",
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return false;
        }

        if (document.querySelector('select[name=day1]').value === "0" || document.querySelector('select[name=day2]').value === "0"
            || document.querySelector('select[name=day3]').value === "0" || document.querySelector('select[name=day4]').value === "0") {
            confirmAlert({
                message: "Please select four distinct preferred times.",
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return false;
        }
        if (!self.state.time1 || !self.state.time2 || !self.state.time3 || !self.state.time4) {
            confirmAlert({
                message: "Please select four distinct preferred times.",
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return false;
        }

        let soonestAllowed = new Date();
        soonestAllowed.setDate(soonestAllowed.getDate() + 2);

        let datetime1 = self.calculatePreferredDateTime(soonestAllowed, document.querySelector('select[name=day1]').value - 1, self.state.time1);
        let datetime2 = self.calculatePreferredDateTime(soonestAllowed, document.querySelector('select[name=day2]').value - 1, self.state.time2);
        let datetime3 = self.calculatePreferredDateTime(soonestAllowed, document.querySelector('select[name=day3]').value - 1, self.state.time3);
        let datetime4 = self.calculatePreferredDateTime(soonestAllowed, document.querySelector('select[name=day4]').value - 1, self.state.time4);

        if (datetime1.getTime() === datetime2.getTime() || datetime1.getTime() === datetime3.getTime() || datetime1.getTime() === datetime4.getTime()
            || datetime2.getTime() === datetime3.getTime() || datetime2.getTime() === datetime4.getTime() || datetime3.getTime() === datetime4.getTime()) {
            confirmAlert({
                message: "Please select four distinct preferred times.",
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return false;
        }

        document.querySelector('#tutoring .no-tutors').style.display = 'none';

        let g = document.querySelector('#tutoring select[name=grade]').value;
        let s = document.querySelector('#tutoring select[name=subject1]').value;
        let sl = document.querySelector('#tutoring input[name=length]:checked').value;
        let dt1 = formatDate(datetime1, true) + ' ' + formatHour(datetime1, true);
        let dt2 = formatDate(datetime2, true) + ' ' + formatHour(datetime2, true);
        let dt3 = formatDate(datetime3, true) + ' ' + formatHour(datetime3, true);
        let dt4 = formatDate(datetime4, true) + ' ' + formatHour(datetime4, true);
        let ga = document.querySelector('#tutoring input[name=get_ahead]').value;

        fetch(API_URL + "lms/available-tutors/?grade=" + g + "&subject=" + s
            + "&session_length=" + sl + "&datetime1=" + dt1 + "&datetime2=" + dt2 + "&datetime3=" + dt3
            + "&datetime4=" + dt4 + "&fetched_tutors=" + JSON.stringify(self.state.fetched_tutors)
            + "&get_all=" + (self.state.get_all_tutors ? 1 : 0) + "&is_mini=" + (self.is_mini ? 1 : 0)
            + "&get_ahead=" + ga,
            { credentials: "include",}
        ).then(function(response) {
            return response.json();
        }).then(function(results) {
            document.querySelectorAll('#tutoring .step2 .loading').forEach(e => e.style.display = 'none');
            document.querySelectorAll('#tutoring .step2 .loaded .tm-loading').forEach(e => e.style.display = 'none');
            document.querySelectorAll('#tutoring .step2 .loaded').forEach(e => e.style.display = 'flex');

            let get_all = self.state.get_all_tutors || !!results['get_all'];
            self.setState({
               get_all_tutors: get_all
            });
            if (results['tutors'] && results['tutors'].length) {
                for (let i in results['tutors']) {
                    let tutor = results['tutors'][i];
                    let name = tutor.name.trim();
                    let slug = name.replaceAll(' ', '-').toLowerCase();
                    let available_time;
                    if (tutor.time !== '') {
                        available_time = new Date(tutor.time);
                    }

                    if (self.is_mini && !get_all) {
                        // If a mini assessment, select tutor immediately if possible and move on
                        document.querySelector('#tutoring .step2').style.display = 'none';
                        document.querySelector('#tutoring .step3').style.display = 'flex';
                        document.querySelector('#tutoring #back-to-step2').classList.remove('current');
                        document.querySelector('#tutoring #back-to-step3').classList.add('current');
                        window.scrollTo({top: 0, behavior: "smooth"});

                        self.setState({
                            tutor_id: tutor.id
                        });
                        return;
                    }

                    let html = '<li class="tutor-profile" data-id="' + tutor.id + '">' +
                        '<div class="profile-image"><img src="' + tutor.photo + '"/></div>' +
                        '<div class="tutor-info">' +
                        '<p class="tutor-name">' + name +'</p>';
                    if (available_time) {
                        html += '<div class="available-at"><p>Available at:</p>' +
                            '<p>' + available_time.toLocaleString('en-us', { weekday: "short", month: "short", day: "numeric", hour: "numeric", minute: "numeric" }) + '</p>' +
                            '</div>';
                    }
                    html += '<a href="' + WEBSITE_URL + '/tutors/tutor/' + slug + '/" data-glightbox="type: external;" class="more-details glightbox">More details about ' + name + '</a>' +
                        '</div>' +
                        '</li>';
                    document.getElementById('tutor-list').insertAdjacentHTML("beforeend", html);
                    self.setState({
                        fetched_tutors: [...self.state.fetched_tutors, tutor.id]
                    });
                }
                lightbox.reload();
            }
            if (results['empty'] || (results['tutors'] && results['tutors'].length < 3)) {
                document.querySelector('.show-more').style.display = 'none';
                if (self.state.fetched_tutors.length === 0) {
                    document.querySelector('#tutoring .no-tutors').style.display = 'block';
                    document.querySelector('#tutoring .loaded').style.display = 'none';
                }
            }
        });

        return true;
    }

    calculatePreferredDateTime(soonestAllowed, chosenWeekday, time) {
        let splitTime = time.split(":");
        let date = new Date(soonestAllowed.getTime());
        date.setDate(soonestAllowed.getDate() + (chosenWeekday + 7 - soonestAllowed.getDay()) % 7);
        date.setHours(splitTime[0], splitTime[1], 0);

        if (date.getTime() < soonestAllowed.getTime()) {
            date.setDate(date.getDate() + 7);
        }

        return date;
    }

    addSession(a) {
        let self = this;
        let now = new Date();
        if (a.date.getTime() - now.getTime() < 48 * 60 * 60 * 1000) {
            confirmAlert({
                message: 'You cannot add or modify sessions within 48 hours. Please contact support at online.tutoring@testingmom.com.',
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return;
        }
        let minutes = a.date.getMinutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        let startHour = a.date.getHours();
        if (startHour < 10) {
            startHour = '0' + startHour;
        }
        self.setState({
            selected_date: startHour + ':' + minutes
        });

        let s = document.getElementById('add-edit-session');
        s.querySelector('h4').innerHTML = 'Schedule Tutoring Session for ' + a.date.toLocaleDateString('en-us', { weekday: 'short' }) + '. ' + (a.date.getMonth() + 1) + '/' + a.date.getDate();
        s.querySelector('input[name=session_id]').value = '';

        s.querySelector('#schedule-session').innerHTML = 'SCHEDULE';
        document.querySelector('.cancellation-policy').style.display = 'none';
        document.getElementById('delete-session').style.display = 'none';
        document.getElementById('schedule-session').style.display = 'block';

        s.querySelector('#start').value = startHour + ':' + minutes;
        s.querySelector('input[name=date]').value = formatDate(a.date);
        s.querySelector('input[name=tutor_id]').value = self.state.tutor_id;
        s.querySelector('input[name=status]').value = 'scheduled';
        s.querySelectorAll('.subject-container').forEach(e => e.style.display = 'none');

        if (document.getElementById('half-hour-length').checked) {
            s.querySelector('#half-hour-session').checked = true;
        } else {
            s.querySelector('#hour-session').checked = true;
        }

        s.style.display = 'block';
    }

    render() {
        let self = this;

        let calendarStart = new Date();
        calendarStart.setDate(calendarStart.getDate() + 2);

        return(
            <div className="wrapper wbkg selected-program cont tutor-select-wrapper" id="tutoring">
                <div className="loading tm-loading"></div>
                <div className="tutor-select">
                    <h1 className="mini-only">Schedule My Mini-Assessment</h1>
                    <div className="steps-visual">
                        <div id="back-to-step1" className="current">
                            <p>STEP 1</p>
                            <p>Student and Schedule Info</p>
                        </div>
                        <div id="back-to-step2">
                            <p>STEP 2</p>
                            <p className="step-title">Select Tutor</p>
                        </div>
                        <div id="back-to-step3">
                            <p>STEP 3</p>
                            <p>Select Time and Date</p>
                        </div>
                    </div>
                    <div className="step step1">
                        <div>
                            <h4>Student Profile and Schedule Preference</h4>
                            <p><strong>Important note:</strong> If you need your first tutoring session within the next
                                48 hours, please email
                                <a href="mailto:online.tutoring@testingmom.com">online.tutoring@testingmom.com</a> and
                                do not
                                fill out this form.</p>
                        </div>
                        <button type="button" className="continue-btn to-step2">Continue to Step 2</button>
                        <div className="profile-columns">
                            <div>
                                <h4>Student Profile</h4>
                                <input name="student_id" type="hidden" value="0"/>
                                <input name="get_ahead" type="hidden" value="0"/>
                                <label>First and Last Name:</label>
                                <input name="name" type="text"/>
                                <label>Current Grade:</label>
                                <select name="grade" defaultValue="0">
                                    <option value="0" disabled>Select Grade</option>
                                    {self.state.grades.map(function(grade) {
                                        return(
                                            <option key={grade.id} value={grade.id}>{grade.name}</option>
                                        )
                                    })}
                                </select>
                                <p className="sidenote">(if summer, grade in fall)</p>
                                <label>Primary Test/Subject:</label>
                                <select name="subject1" defaultValue="0">
                                    <option value="0" disabled>Select Subject</option>
                                    {self.state.subjects.map(function(subject) {
                                        let subjectClass = '';
                                        subjectClass += (subject.miniassessment ? 'mini ' : '');
                                        subjectClass += (subject.display_members ? 'members': '');
                                        return (
                                            <option key={subject.id} value={subject.id} className={subjectClass}>{subject.name}</option>
                                        )
                                    })}
                                </select>
                                <label>Secondary Test/Subject</label>
                                <select name="subject2">
                                    <option value="0">None</option>
                                    {self.state.subjects.map(function(subject) {
                                        let subjectClass = '';
                                        subjectClass += (subject.miniassessment ? 'mini ' : '');
                                        subjectClass += (subject.display_members ? 'members': '');
                                        return (
                                            <option key={subject.id} value={subject.id} className={subjectClass}>{subject.name}</option>
                                        )
                                    })}
                                </select>
                                <div className="regular-session">
                                    <label>Session Length</label>
                                    <div className="session-length">
                                        <div>
                                            <input type="radio" name="length" id="half-hour-length" value="30" defaultChecked={true}/>
                                                <label htmlFor="half-hour-length">1/2 Hour Session</label>
                                        </div>
                                        <div>
                                            <input type="radio" name="length" id="hour-length" value="60"/>
                                                <label htmlFor="hour-length">1 Hour Session</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="schedule-preference">
                                <h4>Schedule Preference</h4>
                                <label>1st Time Preference:</label>
                                <div>
                                    <select name="day1" defaultValue="0">
                                        <option value="0" disabled>Select Day</option>
                                        {self.days.map(function(day, index) {
                                            return (
                                                <option key={day} value={index + 1}>{day}</option>
                                            )
                                        })}
                                    </select>
                                    <div className="time-container">
                                        <Flatpickr className="mdl-textfield__input" id="start1"
                                           options={{
                                               enableTime: true,
                                               noCalendar: true,
                                               dateFormat: "H:i",
                                               altInput: true,
                                               altFormat: "h:i K",
                                               allowInput: true,
                                               minuteIncrement: 15,
                                               defaultMinute: 0,
                                               defaultDate: "12:00"
                                           }}
                                           value={self.state.time1}
                                           onChange={([date]) => {this.setState({ time1: date.getHours() + ':' + date.getMinutes() })}}
                                        />
                                        <p className="sidenote">(all times in <span>UTC</span>)</p>
                                    </div>
                                </div>
                                <label>2nd Time Preference:</label>
                                <div>
                                    <select name="day2" defaultValue="0">
                                        <option value="0" disabled>Select Day</option>
                                        {self.days.map(function(day, index) {
                                            return (
                                                <option key={day} value={index + 1}>{day}</option>
                                            )
                                        })}
                                    </select>
                                    <div className="time-container">
                                        <Flatpickr className="mdl-textfield__input" id="start2"
                                           options={{
                                               enableTime: true,
                                               noCalendar: true,
                                               dateFormat: "H:i",
                                               altInput: true,
                                               altFormat: "h:i K",
                                               allowInput: true,
                                               minuteIncrement: 15,
                                               defaultMinute: 0,
                                               defaultDate: "12:00"
                                           }}
                                           value={self.state.time2}
                                           onChange={([date]) => {this.setState({ time2: date.getHours() + ':' + date.getMinutes() })}}
                                        />
                                        <p className="sidenote">(all times in <span>UTC</span>)</p>
                                    </div>
                                </div>
                                <label>3rd Time Preference:</label>
                                <div>
                                    <select name="day3" defaultValue="0">
                                        <option value="0" disabled>Select Day</option>
                                        {self.days.map(function(day, index) {
                                            return (
                                                <option key={day} value={index + 1}>{day}</option>
                                            )
                                        })}
                                    </select>
                                    <div className="time-container">
                                        <Flatpickr className="mdl-textfield__input" id="start3"
                                           options={{
                                               enableTime: true,
                                               noCalendar: true,
                                               dateFormat: "H:i",
                                               altInput: true,
                                               altFormat: "h:i K",
                                               allowInput: true,
                                               minuteIncrement: 15,
                                               defaultMinute: 0,
                                               defaultDate: "12:00"
                                           }}
                                           value={self.state.time3}
                                           onChange={([date]) => {this.setState({ time3: date.getHours() + ':' + date.getMinutes() })}}
                                        />
                                        <p className="sidenote">(all times in <span>UTC</span>)</p>
                                    </div>
                                </div>
                                <label>4th Time Preference:</label>
                                <div>
                                    <select name="day4" defaultValue="0">
                                        <option value="0" disabled>Select Day</option>
                                        {self.days.map(function(day, index) {
                                            return (
                                                <option key={day} value={index + 1}>{day}</option>
                                            )
                                        })}
                                    </select>
                                    <div className="time-container">
                                        <Flatpickr className="mdl-textfield__input" id="start4"
                                           options={{
                                               enableTime: true,
                                               noCalendar: true,
                                               dateFormat: "H:i",
                                               altInput: true,
                                               altFormat: "h:i K",
                                               allowInput: true,
                                               minuteIncrement: 15,
                                               defaultMinute: 0,
                                               defaultDate: "12:00"
                                           }}
                                           value={self.state.time4}
                                           onChange={([date]) => {this.setState({ time4: date.getHours() + ':' + date.getMinutes() })}}
                                        />
                                        <p className="sidenote">(all times in <span>UTC</span>)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="continue-btn to-step2 desktop">Continue to Step 2</button>
                    </div>
                    <div className="step step2">
                        <div className="loading-container">
                            <div className="loading tm-loading"></div>
                            <p>Matching you with a tutor...</p>
                        </div>
                        <div className="loaded">
                            <div>
                                <h4>Select a tutor</h4>
                                <p>Click a tutor to select them.</p>
                            </div>
                            <button type="button" className="continue-btn disabled to-step3">View Selected Tutor's
                                Schedule
                            </button>
                            <ul id="tutor-list" className="tutor-list"></ul>
                            <div className="loading tm-loading"></div>
                            <a href="" className="show-more">Show More Available Tutors</a>
                            <button type="button" className="continue-btn disabled to-step3 bottom">View Selected
                                Tutor's Schedule
                            </button>
                        </div>
                        <p className="no-tutors">We don't have any tutors for your primary subject and grade. Please
                            update your subject and grade selection
                            or contact <a
                                href="mailto:online.tutoring@testingmom.com">online.tutoring@testingmom.com</a> for
                            additional help.</p>
                    </div>
                    <div className="step step3">
                        <h4>Select Time and Date</h4>
                        <p>Click a white block on the calendar to schedule your session. Sessions must be scheduled at
                            least 48 hours in advance.</p>
                        <p>If you need your first tutoring session within the next 48 hours, please email
                            <a href="mailto:online.tutoring@testingmom.com">online.tutoring@testingmom.com</a> and do
                            not
                            fill out this form.</p>
                        <div id="calendar_0" className="calendar has-toolbar">
                            {self.state.tutor_id > 0 &&
                                <FullCalendar
                                    ref={self.calendarComponentRef}
                                    plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                                    timeZone='local'
                                    headerToolbar={
                                        window.innerWidth < 680 ? {
                                            left: 'timeGridWeek,timeGridDay',
                                            center: 'title',
                                            right: 'prev,next'
                                        } : {
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                        }
                                    }
                                    views={{
                                        timeGridDay: {
                                            dayHeaderFormat: { weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true }
                                        }
                                    }}
                                    initialView={window.innerWidth < 680 ? 'timeGridDay':'timeGridWeek'}
                                    eventDisplay='block'
                                    contentHeight="auto"
                                    expandRows={true}
                                    slotMinTime='06:00:00'
                                    slotMaxTime='23:00:00'
                                    allDaySlot={false}
                                    events={API_URL + 'lms/tutor-schedule/?tutor_id=' + self.state.tutor_id}
                                    eventContent=''
                                    dateClick={self.addSession}
                                    initialDate={calendarStart}
                                />
                            }
                        </div>
                    </div>
                </div>
                <AddSession
                    isTutor={false}
                    selectedDate={self.state.selected_date}
                    selectedStudentId={self.state.student_id}
                    selectedStudentGrade={self.state.student_grade}
                    allowSessionModification={() => true}
                    afterSessionChange={self.overviewRedirect}
                    firstSession={true}
                    isMini={self.is_mini}
                />
                <link rel="stylesheet" href="//cdn.testingmom.com/css/glightbox.min.css" />
            </div>
        )
    }
}

export default TutorSelection;