import React, {Component} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid'
import './Tutoring.css'

import AddSession from "./AddSession";
import StudentSelector from "./StudentSelector";
import {formatDate, formatHour, formatAMPM} from "./Utils";
import {API_URL} from "../common/Urls";
import TutorTabs from "./TutorTabs";
import {ApiFetch} from "../common/ApiFetch";

const moment = require('moment-timezone');
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class TutoringCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            end_time: "",
            selected_date: "",
            student_grade: "",
            student_id: 0,
            student_name: "",
            students: [],
            tutor_id: 0,
        }

        this.data_by_student = {};
        this.calendarComponentRef = React.createRef();
        this.first_calendar_loaded = false;
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.url_params = window.location.search ? new URLSearchParams(window.location.search) : null;

        this.selectStudent = this.selectStudent.bind(this);
        this.setActiveTutor = this.setActiveTutor.bind(this);
        this.allowSessionModification = this.allowSessionModification.bind(this);
        this.editSession = this.editSession.bind(this);
        this.editSessionTutor = this.editSessionTutor.bind(this);
        this.setEventContent = this.setEventContent.bind(this);
        this.addSession = this.addSession.bind(this);
        this.dragSession = this.dragSession.bind(this);
        this.onSessionHover = this.onSessionHover.bind(this);
        this.onSessionHoverEnd = this.onSessionHoverEnd.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
        this.reloadCalendar = this.reloadCalendar.bind(this);
    }

    componentDidMount() {
        let self = this;

        fetch(API_URL + "lms/user-tutoring-schedule/", {
            credentials: "include",
        }).then(function(response) {
            return response.json();
        }).then(function(results) {
            let body = document.getElementById('tutoring');
            body.querySelector('.tm-loading').style.display = 'none';
            if (results && Object.keys(results).length > 0) {
                if (self.props.user.tutor) {
                    self.setState({
                        tutor_id : results['tutor_id']
                    });
                    let startDate = self.url_params ? self.url_params.get('date') : null;
                    self.reloadCalendar(startDate);
                } else {
                    self.data_by_student = results;
                    let studentList = [];
                    for (let name in results) {
                        studentList.push({'id': results[name]['id'], 'name': name});
                    }
                    self.setState({
                        students: studentList,
                    });
                    self.selectStudent(Object.keys(self.data_by_student)[0]);
                }
                body.querySelector('.calendar-container').style.display = 'block';
            } else {
                body.querySelector('.sign-up').style.display = 'block';
            }
        });
    }

    reloadCalendar(startDate=null) {
        let self = this;
        let calendarApi = self.calendarComponentRef.current.getApi();
        if (startDate) {
            calendarApi.gotoDate(startDate);
        }
        calendarApi.refetchEvents();
    }

    setEventContent(eventInfo) {
        let self = this;
        if (!self.props.user.tutor) {
            if (eventInfo && eventInfo.event && eventInfo.event.start && eventInfo.event.end
                && !eventInfo.event.extendedProps.unavailable && !eventInfo.event.extendedProps.timeOff) {
                return {html: eventInfo.event.extendedProps.customHtml}
            }
            return '';
        }

        let statusIcon = "";
        if (eventInfo.event.extendedProps.tutoringSession) {
            if (eventInfo.event.extendedProps.locked) {
                statusIcon = "<i class='fas fa-lock'></i> ";
            }
            let status = eventInfo.event.extendedProps.status;
            switch (status) {
                case "missed":
                    statusIcon += "✖ ";
                    break;
                case "completed":
                    statusIcon += "✔ ";
                    break;
            }
        }
        if (eventInfo && eventInfo.event && eventInfo.event.start && eventInfo.event.end
            && !eventInfo.event.extendedProps.unavailable && !eventInfo.event.extendedProps.timeOff) {
            return {html: statusIcon + eventInfo.event.extendedProps.customHtml}
        }
        return '';
    }

    editSessionTutor(a) {
        let self = this;
        if (a.event.extendedProps.unavailable || a.event.extendedProps.timeOff) {
            return;
        }
        let s = document.getElementById('add-edit-session');
        s.style.display = 'block';
        self.setState({
            selected_date: a.event.start,
            end_time: a.event.end
        });
        s.querySelector('input[name=session_id]').value = a.event.extendedProps.session_id;
        s.querySelector('#start').value = formatHour(a.event.start);
        s.querySelector('#end').value = formatHour(a.event.end);
        if (a.event.extendedProps.subject_name) {
            s.querySelector('input[name=subject]').value = a.event.extendedProps.subject_name;
        }
        if (a.event.extendedProps.status) {
            s.querySelector('select[name=status]').value = a.event.extendedProps.status;
            s.querySelector('select[name=status]').disabled = a.event.extendedProps.status === "cancelled";
        }
        if (a.event.extendedProps.internal_notes) {
            s.querySelector('textarea[name=internal_notes]').value = a.event.extendedProps.internal_notes.replace(/\\(.)/mg, "$1");
        }
        if (a.event.extendedProps.external_notes) {
            s.querySelector('textarea[name=external_notes]').value = a.event.extendedProps.external_notes.replace(/\\(.)/mg, "$1");
        }
    }

    editSession(a) {
        let self = this;
        if (a.event.extendedProps.unavailable || a.event.extendedProps.timeOff) {
            return;
        }
        let s = document.getElementById('add-edit-session');
        s.style.display = 'block';
        self.setState({
            selected_date: a.event.start
        });
        if (a.event.extendedProps.tutoringSession) {
            s.querySelector('input[name=session_id]').value = a.event.extendedProps.session_id;
            if (a.event.end - a.event.start >= 60 * 60 * 1000) {
                document.getElementById('hour-session').checked = true;
            } else {
                document.getElementById('half-hour-session').checked = true;
            }
            document.querySelector('.cancellation-policy').style.display = 'block';

            // Only allow rescheduling/cancelling if the event is more than 48 hours away
            if (moment(a.event.start).diff(moment(), 'days') <= 1) {
                s.querySelector('h4').innerHTML = 'Tutoring Session Details for ' + a.event.start.toLocaleDateString('en-us', { weekday: 'short' }) + '. ' + (a.event.start.getMonth() + 1) + '/' + a.event.start.getDate();
                document.getElementById('delete-session').style.display = 'none';
                document.getElementById('schedule-session').style.display = 'none';
            } else {
                s.querySelector('h4').innerHTML = 'Reschedule Tutoring Session on ' + a.event.start.toLocaleDateString('en-us', { weekday: 'short' }) + '. ' + (a.event.start.getMonth() + 1) + '/' + a.event.start.getDate();
                s.querySelector('#schedule-session').innerHTML = 'RESCHEDULE';
                document.getElementById('delete-session').style.display = 'block';
                document.getElementById('schedule-session').style.display = 'block';
            }
        }
        s.querySelector('#start').value = formatHour(a.event.start);
        s.querySelector('input[name=date]').value = formatDate(a.event.start);
        s.querySelector('input[name=tutor_id]').value = a.event.extendedProps.tutor_id;
        s.querySelector('input[name=subject_id]').value = a.event.extendedProps.subject;
        if (a.event.extendedProps.status) {
            s.querySelector('input[name=status]').value = a.event.extendedProps.status;
        } else {
            s.querySelector('input[name=status]').value = 'scheduled';
        }
        if (a.event.extendedProps.subject) {
            s.querySelector('select[name=subject]').value = a.event.extendedProps.subject;
        }
    }

    addSession(a) {
        let self = this;
        let now = new Date();
        if (a.date.getTime() - now.getTime() < 48 * 60 * 60 * 1000) {
            confirmAlert({
                message: 'You cannot add or modify sessions within 48 hours. Please contact support at online.tutoring@testingmom.com.',
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return;
        }
        let s = document.getElementById('add-edit-session');
        s.querySelector('h4').innerHTML = 'Schedule Tutoring Session for ' + a.date.toLocaleDateString('en-us', { weekday: 'short' }) + '. ' + (a.date.getMonth() + 1) + '/' + a.date.getDate();
        s.querySelector('input[name=session_id]').value = '';

        s.querySelector('#schedule-session').innerHTML = 'SCHEDULE';
        document.querySelector('.cancellation-policy').style.display = 'none';
        document.querySelector('#delete-session').style.display = 'none';
        document.querySelector('#schedule-session').style.display = 'block';

        let minutes = a.date.getMinutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        let startHour = a.date.getHours();
        if (startHour < 10) {
            startHour = '0' + startHour;
        }
        s.querySelector('#start').value = startHour + ':' + minutes;
        self.setState({
            selected_date: startHour + ':' + minutes
        })
        s.querySelector('input[name=date]').value = formatDate(a.date);
        s.querySelector('input[name=tutor_id]').value = self.state.tutor_id;
        s.querySelector('input[name=status]').value = 'scheduled';
        s.style.display = 'block';
    }

    dragSession(info) {
        let self = this;
        if (!window.confirm("Are you sure about this change?")) {
            info.revert();
            return;
        }
        if (!self.allowSessionModification(true)) {
            info.revert();
            return false;
        }
        if (moment(info.oldEvent.start).diff(moment(), 'days') <= 1
            || moment(info.event.start).diff(moment(), 'days') <= 1) {
            info.revert();
            confirmAlert({
                message: 'You cannot add or modify sessions within 48 hours. Please contact support at online.tutoring@testingmom.com.',
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return false;
        }

        let data = {
            'start_time': info.event.start.toISOString().slice(0, 19).replace('T', ' '),
            'end_time': info.event.end.toISOString().slice(0, 19).replace('T', ' '),
            'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'status': info.event.extendedProps.status,
            'student_id': info.event.extendedProps.student_id,
            'tutor_id': info.event.extendedProps.tutor_id,
            'grade': self.data_by_student[self.state.student_name]["grade"].toString(),
            'subject': info.event.extendedProps.subject,
            'session_id': info.event.extendedProps.session_id,
            'source': 'members'
        };

        ApiFetch('lms/student-schedule/', 'POST', data).then(function (response) {
            if (response.error) {
                confirmAlert({
                    message: response.error,
                    buttons: [
                        {
                            label: 'Ok'
                        }
                    ]
                });
                info.revert();
                return false;
            } else {
                document.getElementById('add-edit-session').style.display = 'none';
                self.reloadCalendar();
            }
        });

        this.onSessionHoverEnd(info);
    }

    onSessionHover(info) {
        let self = this;
        if (info.event.extendedProps.tutoringSession || info.event.extendedProps.onlineClass) {
            let shortTz = new Date().toLocaleString('en-us', {timeZone: this.timezone, timeZoneName: "shortGeneric"}).split(" ")[3];
            let start = formatAMPM(info.event.start) + " " + shortTz;
            let end = formatAMPM(info.event.end) + " " + shortTz;

            let tooltip = '<p class="event-tooltip">' + start + ' - ' + end + '</p>';
            document.body.insertAdjacentHTML('beforeend', tooltip);
            info.el.addEventListener('mousemove', self.showTooltip);
        }
    }

    onSessionHoverEnd(info) {
        let self = this;
        let tt = document.querySelector('.event-tooltip');
        if (tt) {
            info.el.removeEventListener('mousemove', self.showTooltip);
            tt.remove();
        }
    }

    showTooltip(e) {
        let tt = document.querySelector('.event-tooltip');
        tt.style.top = (e.pageY + 10) + "px";
        tt.style.left = (e.pageX + 20) + "px";
    }

    selectStudent(name) {
        let self = this;
        self.setState({
            student_name: name,
            student_id: self.data_by_student[name]['id'],
            student_grade: self.data_by_student[name]['grade'].toString()
        }, () => {
            let no_tutors = document.querySelector('#tutoring .no-tutors');
            let calendar = document.querySelector('#tutoring .calendar');
            let selectedTutor = 0;
            let tutors = self.data_by_student[self.state.student_name]["tutors"];
            if (tutors.length) {
                no_tutors.style.display = 'none';
                calendar.style.display = 'block';
                selectedTutor = tutors[0].id;
                if (!self.first_calendar_loaded && self.url_params) {
                    let setTutor = self.url_params.get('tid');
                    for (let t of tutors) {
                        if (t.id === setTutor) {
                            selectedTutor = setTutor;
                        }
                    }
                }
                self.setActiveTutor(selectedTutor);
            } else {
                no_tutors.style.display = 'block';
                let first_session = no_tutors.querySelector('.first-session');
                first_session.href = "/tutoring/select-tutor/?id=" + self.data_by_student[self.state.selected_student]["id"];
                if (self.data_by_student[self.state.selected_student]['minis_remaining'] > 0) {
                    first_session.href += "&mini=1";
                    first_session.innerHTML = "Schedule Mini Assessment";
                } else {
                    first_session.innerHTML = "Schedule First Session";
                }
                calendar.style.display = 'none';
            }
        });
    }

    setActiveTutor(tutorId) {
        let self = this;
        self.setState({
            tutor_id: tutorId
        }, () => {
            // Display tutor's subject options
            let subjectSelect = document.querySelector('#tutoring #add-edit-session #subject-select');
            let selectEl = document.getElementById('subject-select');
            if (subjectSelect) {
                for (let i = selectEl.options.length - 1; i >= 0; i--) {
                    selectEl.remove(i);
                }
                let tutors = self.data_by_student[self.state.student_name]["tutors"];
                for (let t in tutors) {
                    if (tutors[t].id === parseInt(self.state.tutor_id)) {
                        let subjects = tutors[t]["subjects"];
                        for (let s in subjects) {
                            subjectSelect.innerHTML += "<option class='subject-option' value='" + subjects[s] + "'>" + s + "</option>";
                        }
                        break;
                    }
                }
            }

            let startDate;
            if (typeof(self.reloadCalendar) !== 'undefined') {
                if (!self.first_calendar_loaded && self.url_params) {
                    startDate = self.url_params.get('date');
                    self.first_calendar_loaded = true;
                }
                self.reloadCalendar(startDate);
            }
        });
    }

    setCookie(name, value, days) {
        let d = new Date();
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    getCookie(name) {
        let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    allowSessionModification(dragged=false) {
        let self = this;

        if (['106', '108'].includes(document.querySelector('input[name=subject_id]').value)) {
            confirmAlert({
                message: 'Sorry, you cannot modify mini assessments. Please contact online.tutoring@testingmom.com if you need urgent support.',
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return false;
        }
        if (document.querySelector('input[name=session_id]').value || dragged) {
            let sessionModCookie = self.getCookie('session-modification');
            if (!sessionModCookie) {
                let modTimes = [Date.now()];
                self.setCookie('session-modification', JSON.stringify(modTimes), 1);
            } else {
                let modTimes = JSON.parse(sessionModCookie);
                modTimes = modTimes.filter(time => (Date.now() - time < 1000 * 60 * 60 * 2));

                if (modTimes.length >= 3) {
                    self.setCookie('session-modification', JSON.stringify(modTimes), 1);
                    confirmAlert({
                        message: 'You may only modify 3 tutoring sessions per 2 hour period.  ' +
                            'Please contact online.tutoring@testingmom.com if you need urgent support.',
                        buttons: [
                            {
                                label: 'Ok'
                            }
                        ]
                    });
                    document.getElementById('add-edit-session').style.display = 'none';
                    return false;
                } else {
                    modTimes.push(Date.now());
                    self.setCookie('session-modification', JSON.stringify(modTimes), 1);
                }
            }
        }
        return true;
    }

    render() {
        let self = this;

        let tutors = [];
        if (self.state.student_name in self.data_by_student && "tutors" in self.data_by_student[self.state.student_name]) {
            tutors = self.data_by_student[self.state.student_name]["tutors"];
        }

        let events_url = self.props.user.tutor ?
            API_URL + 'lms/tutor-availability/?tutor_id=' + self.state.tutor_id
            : API_URL + 'lms/student-tutor-schedule/?student_id=' + self.state.student_id + '&tutor_id=' + self.state.tutor_id

        return (
            <>
            <div className="wrapper wbkg selected-program cont" id="tutoring">
                <div className="loading tm-loading"></div>
                <div className="calendar-container">
                    {!self.props.user.tutor ?
                    <>
                    <h4>TUTOR AVAILABILITY</h4>
                    <StudentSelector
                        isTutor={false}
                        students={self.state.students}
                        onStudentSelect={self.selectStudent}
                        selectedStudent={self.state.student_name}
                    />
                    <TutorTabs
                        tutors={tutors}
                        onTutorSelect={self.setActiveTutor}
                        selectedTutor={self.state.tutor_id}
                    />
                    <div className="no-tutors">
                        <a className="btn first-session" href="/tutoring/select-tutor/">Schedule First Session</a>
                    </div>
                    </>
                    :
                    <h4>MY CALENDAR</h4>
                    }
                    <div id="calendar_0" className="calendar has-toolbar">
                        {self.state.tutor_id > 0 &&
                        <FullCalendar
                            ref={self.calendarComponentRef}
                            plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                            timeZone='local'
                            headerToolbar={
                                window.innerWidth < 680 ? {
                                    left: 'timeGridWeek,timeGridDay',
                                    center: 'title',
                                    right: 'prev,next'
                                } : {
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                }
                            }
                            views={{
                                timeGridDay: {
                                    dayHeaderFormat: { weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true }
                                }
                            }}
                            initialView={window.innerWidth < 680 ? 'timeGridDay':'timeGridWeek'}
                            eventDisplay='block'
                            contentHeight="auto"
                            expandRows={true}
                            slotMinTime='06:00:00'
                            slotMaxTime='23:00:00'
                            allDaySlot={false}
                            events={events_url}
                            eventDidMount={function (info) {
                                if (info.event.extendedProps.status && info.event.extendedProps.status === "cancelled") {
                                    info.el.classList.add('strike');
                                }
                            }}
                            eventDataTransform={function (event) {
                                if (self.props.user.tutor) {
                                    event.startEditable = false;
                                }
                            }}
                            eventContent={self.setEventContent}
                            eventClick={self.props.user.tutor ? self.editSessionTutor : self.editSession}
                            dateClick={self.addSession}
                            eventDrop={self.dragSession}
                            eventMouseEnter={self.onSessionHover}
                            eventMouseLeave={self.onSessionHoverEnd}
                        />
                        }
                    </div>
                </div>
                <div className="sign-up">
                    <p>You don't have any students signed up for online tutoring!</p>
                    <a className="btn bkg_org sign-up-btn" href={WEBSITE_URL + "tutoring/"}>Sign up for
                        one-to-one tutoring</a>
                </div>
                <AddSession
                    isTutor={self.props.user.tutor}
                    endTime={self.state.end_time}
                    selectedDate={self.state.selected_date}
                    selectedStudentId={self.state.student_id}
                    selectedStudentGrade={self.state.student_grade}
                    allowSessionModification={self.allowSessionModification}
                    afterSessionChange={self.reloadCalendar}
                />
            </div>
            <link rel="stylesheet" href="//cdn.testingmom.com/crm/fullcalendar.min.css" />
            </>
        )
    }
}

export default TutoringCalendar;