import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class StudentSelector extends Component {
    constructor(props) {
        super(props);

        this.searchStudents = this.searchStudents.bind(this);
        this.selectStudent = this.selectStudent.bind(this);
        this.hideStudentList = this.hideStudentList.bind(this);
        this.toggleStudentList = this.toggleStudentList.bind(this);
    }

    componentDidMount() {
        let self = this;

        // Close student dropdown when clicking anywhere else on page
        document.querySelector('body').addEventListener('click', self.hideStudentList);
    }

    searchStudents() {
        let self = this;

        let other_students = document.getElementById('other-students');
        let student_search = document.getElementById('student-search');
        let students = other_students.querySelectorAll('.student-option');

        if (students) {
            for (let s in students) {
                if (students.hasOwnProperty(s)) {
                    if (students[s].innerHTML === self.props.selectedStudent) continue;
                    students[s].style.display = (student_search.value === "" || students[s].innerHTML.toLowerCase().includes(student_search.value))
                        ? "block" : "none";
                }
            }
        }
    }

    selectStudent(event) {
        let self = this;
        let el = event.target;

        let other_students = document.querySelector('#other-students');
        let students = other_students.querySelectorAll('.student-option');
        if (students) {
            for (let s in students) {
                if (students.hasOwnProperty(s)) {
                    students[s].style.display = (students[s].innerHTML === el.dataset.name) ? "none" : "block";
                }
            }
        }
        other_students.style.display = "none";
        other_students.scrollTop = 0;
        document.querySelector('#selected-student h1').innerHTML = el.dataset.name;

        self.props.onStudentSelect(el.dataset.name);
    }

    hideStudentList(event) {
        if (!event.target.closest('.student-dropdown')) {
            let other_students = document.getElementById('other-students');
            other_students.style.display = 'none';
            other_students.scrollTop = 0;
        }
    }

    toggleStudentList() {
        let other_students = document.getElementById('other-students');
        other_students.style.display = (other_students.style.display === "block") ? "none" : "block";
        other_students.scrollTop = 0;
    }

    componentWillUnmount() {
        let self = this;
        document.querySelector('body').removeEventListener('click', self.hideStudentList);
    }

    render() {
        let self = this;
        let showDropdown = self.props.students.length > 1;

        return (
            <div className="student-dropdown">
                <div className="student-name" id="selected-student" onClick={self.toggleStudentList}>
                    <h1>{self.props.selectedStudent}</h1>
                    {showDropdown &&
                        <FontAwesomeIcon icon={"chevron-down"} className={"fas fa-chevron-down"}/>
                    }
                </div>
                <ul className="other-students" id="other-students">
                    {self.props.isTutor &&
                        <li key={"search"} className="search-li">
                            <input type="text" placeholder="Search..." id="student-search" onChange={self.searchStudents} />
                        </li>
                    }
                    {self.props.students.length > 0 && self.props.students.map(function (student) {
                        let displayStudent = (student.name === self.props.selectedStudent) ? ' selected' : '';
                        if (!("unread" in student)) {
                            student.unread = '0';
                        }
                        return (
                            <li key={student.id} className={'student-option' + displayStudent}
                                data-unread={student.unread} data-id={student.id} data-name={student.name}
                                onClick={self.selectStudent}>
                                {student.name}
                            </li>
                        )
                    })
                    }
                </ul>
            </div>
        );
    }
}

export default StudentSelector;