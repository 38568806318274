import React from 'react';
import Link from '../common/Link';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './Tutoring.css'

import Message from "./Message";
import Session from "./Session";
import StudentSelector from "./StudentSelector";
import {API_URL} from "../common/Urls";
import TutorTabs from "./TutorTabs";
import TutoringComponent from "./TutoringComponent";

const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;
const moment = require('moment-timezone');
const ApiFetch = require('../common/ApiFetch').ApiFetch;


class TutoringOverview extends TutoringComponent {
    constructor(props) {
        super(props);

        let query_string = window.location.search;
        this.state = {
            messages: [],
            selected_date_moment: moment().startOf('week'),
            selected_student: "",
            selected_tutor_id: 0,
            sessions: [],
            student_id: 0,
            students: [],
            time: moment(),
        }

        this.dates_retrieved = [];
        this.first_calendar_loaded = false;
        this.full_tutoring_sessions = {};
        this.get_messages_interval = null;
        this.get_messages_seconds = 60;
        this.is_tutor = false;
        this.messages_loaded = false;
        this.student_id = 0;
        this.timer_interval = null;
        this.tutor_info = null;
        this.tutoring_sessions = {};
        this.url_params = query_string ? new URLSearchParams(query_string) : null;
        this._isMounted = false;

        this.selectStudent = this.selectStudent.bind(this);
        this.showSubjects = this.showSubjects.bind(this);
        this.setActiveTutor = this.setActiveTutor.bind(this);
        this.loadSessionCounts = this.loadSessionCounts.bind(this);
        this.sortFullSessions = this.sortFullSessions.bind(this);
        this.showHomework = this.showHomework.bind(this);
        this.fetchSessions = this.fetchSessions.bind(this);
        this.showSessions = this.showSessions.bind(this);
        this.saveMessage = this.saveMessage.bind(this);
        this.afterReplySaved = this.afterReplySaved.bind(this);
        this.setTutoringMessages = this.setTutoringMessages.bind(this);
        this.addNewMessage = this.addNewMessage.bind(this);
        this.updateMessage = this.updateMessage.bind(this);
        this.setMessageRead = this.setMessageRead.bind(this);
        this.getTutoringMessages = this.getTutoringMessages.bind(this);
    }

    componentDidMount() {
        let self = this;
        self._isMounted = true;

        if (self.url_params) {
            let showSuccessOverlay = self.url_params.get('scheduled');
            if (showSuccessOverlay) {
                let mini_scheduled = self.url_params.get('mini');
                let s = document.getElementById('first-session-scheduled');
                s.style.display = 'block';
                if (mini_scheduled) {
                    s.querySelectorAll('.btn-row').forEach(e => e.style.display = 'none');
                    s.querySelector('.overlay').style.maxHeight = '155px';
                    s.querySelector('h1').style.marginBottom = '0';
                }

                s.addEventListener('click', function (e) { //TODO remove listener
                    if (e.target.id === 'first-session-scheduled') {
                        s.style.display = 'none';
                    }
                });

                let success_exit = document.querySelector('#tutoring #first-session-scheduled .exit');
                if (success_exit) {
                    success_exit.addEventListener('click', function() { //TODO remove listener
                        s.style.display = 'none';
                    });
                }
            }
        }

        ApiFetch('lms/user-tutoring-overview', 'GET').then(function (results) {
            let body = document.getElementById('tutoring');
            body.querySelectorAll('div.tm-loading').forEach(e => e.style.display = 'none');
            if (results && Object.keys(results).length > 0) {
                if (results["tutor"]) {
                    self.is_tutor = true;
                    self.tutor_info = results["tutor"];
                    self.setState({
                        selected_tutor_id: results["tutor"]["id"]
                    });
                    let hw_tutor_id = document.querySelector('#tutoring #hw-form input[name=tutor_id]');
                    if (hw_tutor_id) {
                        hw_tutor_id.value = results["tutor"]["id"]
                    }

                    if (Object.keys(results["students"]).length === 0) {
                        body.querySelectorAll('.sign-up').forEach(e => e.style.display = 'block');
                        return;
                    }
                }

                self.data_by_student = results["students"];
                let studentList = [];
                for (let name in self.data_by_student) {
                    if (self.data_by_student.hasOwnProperty(name)) {
                        studentList.push(self.loadNewStudent(name, self.data_by_student[name]['id']));
                        self.tutoring_sessions[name] = {};
                    }
                }
                self.setState({
                   students: studentList
                });
                // TODO turn into component
                if (!self.is_tutor) self.loadSessionCounts(results['credits_used'], results['credits_scheduled'], results['credits_left']);
                let searchParams = new URLSearchParams(window.location.search)
                let studentParam = searchParams.get('student');
                if (studentParam && studentParam in self.tutoring_sessions) {
                    self.selectStudent(studentParam);
                } else {
                    self.selectStudent(Object.keys(self.tutoring_sessions)[0]);
                }

                self.fetchSessions();
                body.querySelector('.overview').style.display = 'block';
                self.setSessionInterval();

                document.querySelector('#tutoring #messages').style.display = 'block';
            } else {
                body.querySelectorAll('.sign-up').forEach(e => e.style.display = 'block');
            }
        });

        let arrows = document.querySelectorAll('#tutoring .calendar-date .arrow');
        if (arrows) {
            for (let a in arrows) {
                if (arrows.hasOwnProperty(a)) {
                    arrows[a].addEventListener('click', function() { //TODO remove listener
                        if (this.classList.contains('left-arrow')) {
                            self.setState({
                               selected_date_moment: self.state.selected_date_moment.clone().subtract(7, 'days')
                            }, self.fetchSessions);
                        } else {
                            self.setState({
                                selected_date_moment: self.state.selected_date_moment.clone().add(7, 'days')
                            }, self.fetchSessions);
                        }
                    });
                }
            }
        }

        let hw_btn = document.querySelector('#tutoring .hw-btn');
        if (hw_btn) {
            hw_btn.addEventListener('click', function() { //TODO remove listener
                self.showHomework(null);
            });
        }

        let scope = document.getElementById('calendar-scope');
        if (scope) {
            scope.addEventListener('change', function() { //TODO remove listener
                self.showSessions();
            });
        }

        let hw_form = document.querySelector('#tutoring #hw-form');
        if (hw_form) {
            hw_form.addEventListener('submit', function(e) { //TODO remove listener
                e.preventDefault();
                let formData = new FormData(this);
                formData.append('student_id', document.querySelector('#tutoring #hw-form input[name=child_id]').value);
                formData.append('api_file_upload', 1);
                fetch(API_URL + 'lms/tutoring-homework/', {
                    headers: {
                        'Accept': 'application/json',
                    },
                    method: "POST",
                    body: formData,
                    mode: "cors",
                    credentials: "include"
                }).then(function() {
                    document.getElementById('homework').style.display = 'none';
                });
            });
        }

        let hw_upload = document.querySelector('#tutoring #hw-upload');
        let hw_label = document.querySelector("#tutoring label[for='hw-upload']");
        if (hw_upload && hw_label) {
            hw_upload.addEventListener('change', function() { //TODO remove listener
                self.removeFileUploads();
                this.form.submit.disabled = false;
                this.form.submit.style.display = "block";
                this.form.querySelector('.hw-notes-entry').style.display = "block";

                for (let i in hw_upload.files) {
                    if (hw_upload.files.hasOwnProperty(i)) {
                        let file = document.createElement("p");
                        file.innerHTML = hw_upload.files[i].name;
                        file.className = "file-upload";
                        hw_label.after(file);
                    }
                }
            });
        }

        let hw_exit = document.querySelector('#tutoring #homework .exit');
        if (hw_exit) {
            hw_exit.addEventListener('click', function() { //TODO remove listener
                document.getElementById('homework').style.display = 'none';
            });
        }

        self.setMessageButtons();
        self.setAddFileButton();

        // The timer lags when user changes tab focus for a long time, so reload when they return
        if (/*@cc_on!@*/false) { // check for Internet Explorer
            document.onfocusin = self.fetchSessions;
        } else {
            window.onfocus = self.fetchSessions;
        }
    }

    selectStudent(name) {
        let self = this;

        self.setState({
            selected_student: name,
            student_id: self.data_by_student[name]["id"]
        }, () => {
            let child_id = document.querySelector('#tutoring #hw-form input[name=child_id]');
            if (child_id) {
                child_id.value = self.state.student_id;
            }
            if (window.history) {
                window.history.pushState({}, '', window.location.pathname + '?student=' + name)
            }

            self.showSubjects();
            self.showSessions();

            if (self.is_tutor) {
                self.getTutoringMessages();
            } else {
                let selectedTutor = 0;
                let tutors = self.data_by_student[self.state.selected_student]["tutors"];
                if (tutors.length) {
                    selectedTutor = tutors[0].id;
                }
                self.setActiveTutor(selectedTutor);
            }
        });

        document.querySelector('#tutoring .reply-box').style.display = 'none';
    }

    showSubjects() {
        let self = this;

        let tests = document.querySelector('#tutoring .active-tests .tests');
        tests.innerHTML = "";
        let subjects = self.data_by_student[self.state.selected_student]["subjects"];
        let tutors = self.data_by_student[self.state.selected_student]["tutors"];
        if (Object.keys(subjects).length > 0) {
            for (let subject in subjects) {
                let photo = "";
                if (self.is_tutor && self.tutor_info.photo) {
                    photo = self.tutor_info.photo;
                } else {
                    for (let j in tutors) {
                        if (subjects[subject]["tutor"] && tutors[j].id === subjects[subject]["tutor"]) {
                            photo = tutors[j].photo;
                        }
                    }
                }
                photo = (photo !== "") ? photo : "https://cdn.testingmom.com/img/seven/logo-2.png";

                tests.innerHTML += '<div class="test">' +
                    '<div class="test-tutor">' +
                    '<img src="' + photo + '"/>' +
                    '</div>' +
                    '<div class="test-info">' +
                    '<p>Test</p>' +
                    '<p class="test-title">' + subjects[subject]["name"] + '</p>' +
                    '<a class="subject-hw" data-subject="' + subject + '">View Homework Assignments</a>' +
                    '</div>' +
                    '<div class="test-grade">' +
                    '<p>Grade</p>' +
                    '<p class="grade">' + self.data_by_student[self.state.selected_student]["grade"] + '</p>' +
                    '</div>' +
                    '</div>';
            }

            let links = tests.querySelectorAll('.subject-hw');
            for (let l in links) {
                if (links.hasOwnProperty(l)) {
                    links[l].addEventListener('click', function () { //TODO remove listener
                        self.showHomework(this.dataset.subject);
                    });
                }
            }
        } else {
            tests.innerHTML = "<div>" + self.state.selected_student + " doesn't have any active tutoring yet!</div>";
        }
    }

    setActiveTutor(tutorId) {
        let self = this;
        self.setState({
            selected_tutor_id: tutorId
        }, () => {
            self.setMessagingInterval();
            // Display tutor's subject options
            let messageTutorInput = document.querySelector('#tutoring .reply-box.new .tutor_id');
            if (messageTutorInput) {
                messageTutorInput.value = parseInt(self.state.selected_tutor_id);
            }
            self.getTutoringMessages();
        });
    }

    loadSessionCounts(used, scheduled, remaining) {
        let countDivs = document.querySelectorAll('#tutoring .session-counts div');
        for (let i in countDivs) {
            if (countDivs.hasOwnProperty(i)) {
                if (countDivs[i].className === "used") {
                    countDivs[i].querySelector('h2').innerHTML = used + '<span>hrs</span>';
                } else if (countDivs[i].className === "scheduled") {
                    countDivs[i].querySelector('h2').innerHTML = scheduled + '<span>hrs</span>';
                } else if (countDivs[i].className === "remaining") {
                    countDivs[i].querySelector('h2').innerHTML = remaining + '<span>hrs</span>';
                }
            }
        }
    }

    resetHwUpload() {
        let self = this;

        let submit = document.querySelector('#tutoring .upload-submit');
        if (submit) {
            submit.style.display = "none";
        }
        let notes = document.querySelector('#tutoring #hw-form .hw-notes-entry');
        if (notes) {
            notes.style.display = "none";
            notes.value = "";
        }
        let hw_upload = document.querySelector('#tutoring #hw-upload');
        if (hw_upload) {
            hw_upload.value = "";
        }
        self.removeFileUploads();
    }

    removeFileUploads() {
        let fileUploads = document.querySelectorAll('#tutoring .file-upload');
        for (let i in fileUploads) {
            if (fileUploads.hasOwnProperty(i)) {
                fileUploads[i].remove();
            }
        }
    }

    sortFullSessions() {
        let self = this;
        for (let day in self.full_tutoring_sessions) {
            self.full_tutoring_sessions[day].sort(self.compareSessions);
        }
    }

    compareSessions(a, b) {
        if (a.start_time < b.start_time) return -1;
        if (a.start_time > b.start_time) return 1;
        return 0;
    }

    showHomework(subject) {
        let self = this;

        let h = document.getElementById('homework');
        let links = document.querySelector('#tutoring #homework .hw-links');
        links.innerHTML = "";

        let subject_id = document.querySelector('#tutoring #hw-form input[name=subject_id]');
        if (subject_id && subject) {
            subject_id.value = subject;
        }

        let queryHtml = "?student_id=" + self.data_by_student[self.state.selected_student]["id"];
        if (subject) {
            queryHtml += "&subject_id=" + subject;
        }

        ApiFetch('lms/student-homework' + queryHtml, 'GET').then(function(results) {
            h.querySelectorAll('.tm-loading').forEach(e => e.style.display = 'none');
            if (results && results.length > 0) {
                let currentSubject = -1;
                let currentHw = -1;
                let html = ""

                for (let i in results) {
                    let file = results[i];
                    let subject = file['homework']['subject']
                    if (currentSubject !== subject) {
                        if (subject !== -1) html += "</div></div>";
                        currentSubject = subject;
                        currentHw = -1;
                        html += "<div class='subj-title'>" + self.data_by_student[self.state.selected_student]["subjects"][subject]["name"] + "</div>";
                    }

                    let newHw = file['homework']['id'] !== currentHw
                    if (newHw) {
                        if (currentHw !== -1) html += "</div></div>";
                        currentHw = file['homework']['id'];
                        let date = moment(file['homework']['date']).format('MMM Do YYYY');
                        html += "<div class='hw'><div class='hw-date'>" + date + ":</div>";
                        if (file['homework']['notes'] && file['homework']['notes'] !== "") {
                            html += "<div class='hw-notes'>" + file['homework']['notes'] + "</div>";
                        }
                        html += "<div class='hw-files'>";
                    }
                    html += "<a target='_blank' href='https://cdn.testingmom.com/tutoring/"
                        + file['homework']['student'] + "/homework/" + file['file'] + "'>"
                        + file['file_name'] + "</a>";
                }
                html += "</div></div>";
                links.innerHTML = html;
                h.querySelectorAll('.no-hw').forEach(e => e.style.display = 'none');
            } else {
                h.querySelectorAll('.no-hw').forEach(e => e.style.display = 'block');
            }
        });

        self.resetHwUpload();
        h.style.display = 'block';
        h.querySelectorAll('.tm-loading').forEach(e => e.style.display = 'block');
        h.addEventListener('click', function (e) { //TODO remove listener
            if (e.target.id === 'homework') {
                h.style.display = 'none';
            }
        });
    }

    fetchSessions() {
        let self = this;

        self.showSessions();
        let date_to_retrieve = self.state.selected_date_moment.format("YYYY-MM-DD");
        let start_moment = self.state.selected_date_moment.clone().subtract(7, 'days');
        let end_moment = self.state.selected_date_moment.clone().add(14, 'days');
        if (!self.dates_retrieved.includes(date_to_retrieve)) {
            document.querySelector('#session-list .tiny-tm-loading').style.display = 'block';
        }
        ApiFetch('lms/user-sessions?start_time=' + start_moment.format('YYYY-MM-DD HH:mm:ss')
            + "&end_time=" + end_moment.format('YYYY-MM-DD HH:mm:ss')).then(function (results) {
            if (!self._isMounted) return;
            self.dates_retrieved.push(date_to_retrieve);
            if (results && results.length > 0) {
                for (let i in results) {
                    let name = results[i].student.name;
                    let day = moment(results[i].start_time).format("YYYY-MM-DD");
                    if (!(day in self.full_tutoring_sessions)) self.full_tutoring_sessions[day] = [];
                    if (!(name in self.tutoring_sessions)) self.tutoring_sessions[name] = {};
                    if (!(day in self.tutoring_sessions[name])) self.tutoring_sessions[name][day] = [];

                    let alreadyExists = false
                    for (let j in self.tutoring_sessions[name][day]) {
                        if (self.tutoring_sessions[name][day][j].id === results[i].id) {
                            alreadyExists = true;
                            break;
                        }
                    }
                    if (!alreadyExists) {
                        self.full_tutoring_sessions[day].push(results[i]);
                        self.tutoring_sessions[name][day].push(results[i]);
                    }
                }
                self.sortFullSessions();
            }
            document.querySelector('#session-list .tiny-tm-loading').style.display = 'none';
            self.showSessions();
        });
    }


    showSessions() {
        let self = this;

        let name = self.state.selected_student;
        let m = self.state.selected_date_moment.clone();
        let sessions;
        let scope = document.getElementById('calendar-scope');
        if (scope && scope.value === "all") {
            sessions = self.full_tutoring_sessions;
        } else {
            sessions = self.tutoring_sessions[name];
        }
        if (!sessions) return; // Avoid attempting to show data before data is fetched

        let session_list = [];
        for (let i = 0; i < 7; i++) {
            let date = m.format("YYYY-MM-DD");
            if (!(date in sessions)) {
                session_list.push({
                    'empty': true,
                    'selected_moment': m.clone(),
                    'key': 'empty_' + i
                });
            } else {
                for (let j in sessions[date]) {
                    let ses = sessions[date][j];
                    let ses_start = new Date(ses.start_time);
                    let displayName = self.is_tutor ? ses.student.name : ses.tutor.name;

                    session_list.push({
                        'empty': false,
                        'first': j === "0",
                        'selected_moment': m.clone(),
                        'info': ses,
                        'start': ses_start,
                        'display_name': displayName,
                        'key': 'session_' + ses.id
                    });
                }
            }
            if (i < 6) m.add(1, "days");
        }
        self._isMounted && self.setState({
            sessions: session_list
        });
    }

    setTutoringMessages(messages) {
        let self = this;

        let first_id = 0;
        let used_ids = [];
        document.querySelectorAll('#messages > .tm-loading').forEach(e => e.style.display = 'none');
        if (messages.length === 0) {
            document.querySelector('.no-messages').style.display = 'block';
            return;
        }
        document.querySelector('.no-messages').style.display = 'none';
        if (messages.length > 2) {
            let oldElement = document.querySelector('#messages .view-more');
            oldElement.style.display = 'block';
            let viewMore = oldElement.cloneNode(true); // Remove old event listeners
            oldElement.parentNode.replaceChild(viewMore, oldElement);
            viewMore.addEventListener('click', function(event) { //TODO remove listener
                document.querySelectorAll('#messages .message-container').forEach(e => e.style.display = 'block');
                viewMore.style.display = 'none';
                for (let m in messages) {
                    if (messages.hasOwnProperty(m)) {
                        if (m >= 2) {
                            self.setMessageRead(messages[m].id, false);
                        }
                    }
                }
                event.preventDefault();
            });
        } else {
            document.querySelector('#messages .view-more').style.display = 'none';
        }
        for (let m in messages) {
            if (messages.hasOwnProperty(m)) {
                let s = messages[m].message;
                if (used_ids.includes(s.id)) {
                    continue;
                }
                used_ids.push(s.id);
                if (!first_id) {
                    first_id = s.id;
                    s.active = true;
                }
                let data = s;
                if (s.from_user && parseInt(self.props.user.id) === s.from_user.id) {
                    data.is_users = true;
                    data.name = 'You';
                } else {
                    data.name = s.from_user.first_name;
                }
                if (s.replies) {
                    for (let r in s.replies) {
                        if (s.replies.hasOwnProperty(r)) {
                            let reply = s.replies[r];
                            data.last_reply = reply.id
                            if (reply.from_user && parseInt(self.props.user.id) === reply.from_user.id) {
                                reply.is_users = true;
                                reply.name = 'You'
                            } else {
                                reply.is_users = false;
                                reply.name = reply.from_user.first_name
                            }
                            if (!reply.sent_at) {
                                reply.sent_at = reply.created_at;
                            }
                            if (reply.attachments) {
                                for (let a in reply.attachments) {
                                    if (reply.attachments.hasOwnProperty(a)) {
                                        reply.attachments[a].attachment =
                                            reply.attachments[a].attachment.replace('tutoring/attachments/', '');
                                    }
                                }
                            }
                        }
                    }
                }
                if (!data.sent_at) {
                    data.sent_at = data.created_at;
                }
                if (data.attachments) {
                    for (let a in data.attachments) {
                        if (data.attachments.hasOwnProperty(a)) {
                            data.attachments[a].attachment =
                                data.attachments[a].attachment.replace('tutoring/attachments/', '');
                        }
                    }
                }
                data.message_id = s.id;
                data.replies = s.replies;
                data.recipient_id = messages[m].id;
                data.reply_length = s.replies.length;
                if (!self.messages_loaded) {
                    self.addNewMessage(data);
                } else if (document.getElementById('message-' + s.id)) {
                    self.updateMessage(data);
                } else {
                    self.addNewMessage(data, true);
                }
                if (parseInt(m) < 2 && !messages[m].read) {
                    self.setMessageRead(messages[m].id, false);
                }
            }
        }
        self.messages_loaded = true;
        self.setDates();
        self.setMessageButtons();
        self.setAddFileButton();
    }

    addNewMessage(data, top) {
        let self = this;
        let display_messages;
        if (top) {
            display_messages = [data, ...self.state.messages];
        } else {
            display_messages = [...self.state.messages, data];
        }
        self.setState({
            messages: display_messages
        });
    }

    updateMessage(data) {
        let self = this;

        let updated_messages = [...self.state.messages];
        for (let m in updated_messages) {
            if (m.message_id === data.message_id) {
                m.replies = data.replies;
            }
        }
        self.setState({
           messages: updated_messages
        });
    }

    saveMessage(el, is_new) {
        let self = this;
        super.saveMessage(el, self.afterReplySaved, self.getTutoringMessages);

        if (is_new) {
            document.querySelector('#tutoring .reply-box.new').style.display = 'none';
            document.querySelector('#tutoring .reply-box.new textarea').value = '';
            document.querySelector('#tutoring .reply-box.new input[name=subject]').value = '';
        }
    }

    afterReplySaved(el, data, id) {
        let self = this;
        el.parentElement.style.display = 'none';

        let bubble_data = {
            'id': id,
            'read': true,
            'is_users': true,
            'sent_at': new Date().toISOString(),
            'name': 'You',
            'subject': data.subject,
            'message': data.message,
            'attachments': []
        }

        let updated_messages = [...self.state.messages]
        for (let i in updated_messages) {
            if (updated_messages[i].id === parseInt(data.reply_id)) {
                updated_messages[i].replies.push(bubble_data);
                updated_messages[i].reply_length = updated_messages[i].replies.length;
            }
        }
        self.setState({
            messages: updated_messages
        }, () => {
            document.querySelectorAll('#message-' + data.reply_id + ' .message')
                .forEach(e => e.style.display = 'block');
            self.setDates();
        });
    }

    setMessageRead(message_id, children) {
        let self = this;
        let data, url, method;
        if (children) {
            data = {'message_id': message_id};
            url = 'lms/bulk-message/';
            method = 'POST';
        } else {
            data = {'read': 1}
            url = 'lms/message/' + message_id + '/';
            method = 'PATCH';
        }
        ApiFetch(url, method, data).then(function (response) {
            //TODO
            // self.fetchMessages();
        });
    }

    getTutoringMessages(hide_loading) {
        let self = this;

        if (!hide_loading) {
            self.setState({
               messages: []
            });
            document.querySelectorAll('#messages > .tm-loading').forEach(e => e.style.display = 'block');
            document.querySelectorAll('#messages .no-messages').forEach(e => e.style.display = 'none');
            document.querySelectorAll('#messages .view-more').forEach(e => e.style.display = 'none');
        }

        ApiFetch('lms/message/?limit=1000&archive=false&tutor=' + self.state.selected_tutor_id + '&student=' + self.state.student_id, 'GET').then(function (response) {
            if (response && response.results) {
                let messages = document.getElementById('messages');
                if (messages) {
                    self.setTutoringMessages(response.results);
                }
                self.setMessagingInterval();
            }
        });
    }

    setMessagingInterval() {
        let self = this;

        if (self.get_messages_interval) {
            clearInterval(self.get_messages_interval);
        }

        self.get_messages_interval = setInterval(function() {
            self._isMounted && self.getTutoringMessages(true);
        }, self.get_messages_seconds * 1000);
    }

    setSessionInterval() {
        let self = this;

        if (self.timer_interval) {
            clearInterval(self.timer_interval);
        }

        self.timer_interval  = setInterval(function () {
            self._isMounted && self.setState({
                time: moment()
            });
        }, 1000);
    }

    componentWillUnmount() {
        let self = this;
        self._isMounted = false;

        if (self.timer_interval) {
            clearInterval(self.timer_interval);
        }
        if (self.get_messages_interval) {
            clearInterval(self.get_messages_interval);
        }

        // The timer lags when user changes tab focus for a long time, so reload when they return
        if (/*@cc_on!@*/false) { // check for Internet Explorer
            document.removeEventListener('focusin', self.fetchSessions);
        } else {
            window.removeEventListener('focus', self.fetchSessions);
        }
    }

    render() {
        let self = this;

        let tutors = [];
        if (self.state.selected_student in self.data_by_student && "tutors" in self.data_by_student[self.state.selected_student]) {
            tutors = self.data_by_student[self.state.selected_student]["tutors"];
        }

        return (
            <div className="wrapper wbkg selected-program cont" id="tutoring">
                <div className="loading tm-loading"></div>
                <div className="overview">
                    <div className="overview-columns">
                        <div>
                            <h4>Tutoring Overview</h4>
                            <StudentSelector
                                isTutor={self.props.user.tutor}
                                students={self.state.students}
                                onStudentSelect={self.selectStudent}
                                selectedStudent={self.state.selected_student}
                            />
                            {!self.props.user.tutor &&
                            <div className="total-sessions">
                                <p className="section-title">Total Tutoring Session Hours</p>
                                <div className="session-counts">
                                    <div className="used">
                                        <h2></h2>
                                        <p>Used</p>
                                    </div>
                                    <div className="scheduled">
                                        <h2></h2>
                                        <p>Scheduled</p>
                                    </div>
                                    <div className="remaining">
                                        <h2></h2>
                                        <p>Remaining</p>
                                    </div>
                                </div>
                                <p className="conversion">1 hour equals Two (2) 30-minute sessions.</p>
                                <div className="btn-row">
                                    <Link to={WEBSITE_URL + "kids/"} className="btn purchase-btn">Purchase
                                        More Sessions</Link>
                                    <Link to="/tutoring/calendar" className="btn">Schedule My Sessions</Link>
                                </div>
                            </div>
                            }
                            {self.props.user.tutor &&
                            <div className="total-sessions">
                                <div className="btn-row">
                                    <Link to="/tutoring/calendar" className="btn">View My Schedule</Link>
                                    <Link to={WEBSITE_URL + "crm/?tutoring=1&tutor-invoices=1"}
                                       className="btn">View My Invoice</Link>
                                </div>
                            </div>
                            }
                            <div id="messages">
                                <p className="section-title">Messages</p>
                                <TutorTabs
                                    tutors={tutors}
                                    onTutorSelect={self.setActiveTutor}
                                    selectedTutor={self.state.selected_tutor_id}
                                />
                                <div className="loading tm-loading"></div>
                                <div className="messages">
                                    {self.state.messages.map(function(message) {
                                        return(
                                            <Message
                                                message_id={message.message_id}
                                                read={message.read}
                                                is_users={message.is_users}
                                                sent_at={message.sent_at}
                                                name={message.name}
                                                subject={message.subject}
                                                message={message.message}
                                                attachments={message.attachments}
                                                replies={message.replies}
                                                reply_length={message.reply_length}
                                                recipient_id={message.recipient_id}
                                                student={message.student}
                                                tutor={message.tutor}
                                                class_id={message.class_id}
                                                showControls={true}
                                                key={'message_' + message.message_id}
                                            />
                                        )
                                    })
                                    }
                                </div>
                                <div className="no-messages">
                                    <p>You don't have any messages.</p>
                                </div>
                                <p className="controls">
                                    <a className="view-more" href="#">View More Messages</a>
                                    <a href="#" className="add-message right">Add a Message</a>
                                    <span className="clear"></span>
                                </p>
                                <div className="reply-box new">
                                    <h4>New Message</h4>
                                    <input type="hidden" className="student_id" value={self.state.student_id}/>
                                    <input type="hidden" className="tutor_id" value={self.state.selected_tutor_id}/>
                                    <input type="text" name="subject" placeholder="Subject"/>
                                    <textarea id="message" name="message" placeholder="Message"></textarea>
                                    <p>Attachments:</p>
                                    <input type="file" name="attachment[]"/>
                                    <a href="#" className="add-file">Add another file</a>
                                    <div className="loading tm-loading tiny-tm-loading"></div>
                                    <button name="send_message">Send New Message</button>
                                </div>
                            </div>
                            <div className="active-tests">
                                <p className="section-title">Active Tests & Classes</p>
                                <div className="tests">
                                </div>
                                {!self.props.user.tutor &&
                                <div className="btn hw-btn">View All Homework</div>
                                }
                            </div>
                        </div>
                        <div id="session-list">
                            <h4>Sessions on Calendar</h4>
                            <div className="calendar-date">
                                <div className="arrow left-arrow"><FontAwesomeIcon icon={"long-arrow-left"} className=" fa fas fa-long-arrow-left"/></div>
                                <div className="arrow right-arrow"><FontAwesomeIcon icon={"long-arrow-right"} className=" fa fas fa-long-arrow-right"/></div>
                                <p className="section-title">{"Week of " + self.state.selected_date_moment.format("MMM DD") + " - " + self.state.selected_date_moment.format("MMM DD, YYYY")}</p>
                                {self.props.user.tutor &&
                                <select id="calendar-scope" defaultValue="all" onChange={self.showSessions}>
                                    <option value="all">All Students</option>
                                    <option value="selected">Selected Student</option>
                                </select>
                                }
                            </div>
                            <div className="loading tm-loading tiny-tm-loading"></div>
                            <ul className="sessions">
                                {self.state.sessions.map(function(session) {
                                    return(
                                        <Session
                                            current_time={self.state.time}
                                            empty={session.empty}
                                            first={session.first}
                                            selectedMoment={session.selected_moment}
                                            info={session.info}
                                            start={session.start}
                                            displayName={session.display_name}
                                            key={session.key}
                                        />
                                    )
                                })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                {!self.props.user.tutor &&
                <div className="sign-up">
                    <p>You don't have any students signed up for online tutoring!</p>
                    <Link className="btn bkg_org sign-up-btn" to={WEBSITE_URL + "tutoring/"}>Sign up for
                        one-to-one tutoring</Link>
                </div>
                }
                {self.props.user.tutor &&
                <div className="sign-up">
                    <p>You don't have any students assigned to you yet!</p>
                    <p>Please contact online.tutoring@testingmom.com if you have any questions.</p>
                </div>
                }
                <div id="homework">
                    <div className="overlay">
                        <div className="exit">x</div>
                        <h4>Homework</h4>
                        <div className="hw-links"></div>
                        <div className="loading tm-loading"></div>
                        {self.props.user.tutor &&
                        <form id="hw-form" action="" method="post">
                            <input type="hidden" name="hw-upload" value="1"/>
                            <div className="no-hw">You haven't assigned any homework yet!</div>
                            <input type="hidden" name="child_id"/>
                            <input type="hidden" name="subject_id"/>
                            <input type="hidden" name="tutor_id"/>
                            <input type="file" id="hw-upload" name="s3_files" multiple/>
                            <label htmlFor="hw-upload" className="upload-hw">Upload Homework</label>
                            <textarea className="hw-notes-entry" placeholder="Add notes (optional)" cols="40" rows="5"
                                      name="notes"></textarea>
                            <input type="submit" className="upload-submit" name="submit" disabled={true}
                                   value="Submit"/>
                        </form>
                        }
                        {!self.props.user.tutor &&
                        <div className="no-hw">You haven't been assigned any homework yet!</div>
                        }
                    </div>
                </div>
                <div id="first-session-scheduled">
                    <div className="overlay">
                        <div className="exit">x</div>
                        <h1>Congrats! You've scheduled <span>your child</span>'s first tutoring session!</h1>
                        <div className="btn-row">
                            <Link className="btn" to="/tutoring/calendar/">Schedule More Sessions</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TutoringOverview;
