import React, {Component} from 'react';
import Flatpickr from "react-flatpickr";
import {confirmAlert} from 'react-confirm-alert';
import {formatDate, formatHour} from "./Utils";

const ApiFetch = require('../common/ApiFetch').ApiFetch;

class AddSession extends Component {
    constructor(props) {
        super(props);

        this.submitting = false;
        this.saveSession = this.saveSession.bind(this);
        this.deleteSession = this.deleteSession.bind(this);
        this.onOverlayClick = this.onOverlayClick.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
    }

    componentDidMount() {
        let s = document.getElementById('add-edit-session');
        s.addEventListener('click', function(e) {
            if (e.target.id === 'add-edit-session') {
                s.style.display = 'none';
            }
        });
    }

    onOverlayClick(e) {
        let self = this;
        if (e.target.id === 'add-edit-session') {
            self.hideOverlay(e);
        }
    }

    hideOverlay(e) {
        e.preventDefault();
        document.getElementById('add-edit-session').style.display = 'none';
    }

    saveSession() {
        let self = this;
        if (self.submitting) return;
        self.submitting = true;

        let sessionOverlay = document.getElementById('add-edit-session');
        let date = sessionOverlay.querySelector('input[name=date]').value;
        let start = new Date(date + 'T' + document.getElementById('start').value + ':00');
        let length = sessionOverlay.querySelector('input[name=end]:checked').value;
        let end = new Date(start.getTime() + length * 60 * 1000);
        if (end <= start) {
            confirmAlert({
                message: 'End time must be after start time.',
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return false;
        }

        if (!self.props.allowSessionModification()) return false;

        document.getElementById('schedule-session').insertAdjacentHTML('afterend','<div class="tm-loading tiny-tm-loading"></div>');
        sessionOverlay.querySelector('.tiny-tm-loading').style.display = 'block';
        document.getElementById('schedule-session').style.display = 'none';

        let data = {
            'start_time': formatDate(start, true) + ' ' + formatHour(start, true),
            'end_time': formatDate(end, true) + ' ' + formatHour(end, true),
            'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'status': sessionOverlay.querySelector('input[name=status]').value,
            'tutor_id': sessionOverlay.querySelector('input[name=tutor_id]').value,
            'session_id': sessionOverlay.querySelector('input[name=session_id]').value,
            'source': 'members'
        };

        let endpoint = 'lms/student-schedule/';
        if (self.props.firstSession) {
            endpoint = 'lms/tutor-schedule/';
            data.student_id = document.querySelector('#tutoring input[name=student_id]').value;
            data.name = document.querySelector('#tutoring input[name=name]').value;
            data.grade = document.querySelector('select[name=grade]').value;
            data.subject = document.querySelector('select[name=subject1]').value;
            data.subject2 = document.querySelector('select[name=subject2]').value;
            data.is_mini = self.props.isMini ? 1 : 0;
        } else {
            data.student_id = self.props.selectedStudentId.toString();
            data.grade = self.props.selectedStudentGrade.toString();
            data.subject = sessionOverlay.querySelector('select[name=subject]').value;
        }

        ApiFetch(endpoint, 'POST', data).then(function (response) {
            self.submitting = false;
            document.getElementById('schedule-session').style.display = 'block';
            sessionOverlay.querySelector('.tiny-tm-loading').remove();
            if (response.error) {
                confirmAlert({
                    message: response.error,
                    buttons: [
                        {
                            label: 'Ok'
                        }
                    ]
                });
                if (self.props.firstSession) {
                    document.querySelector('#tutoring input[name=student_id]').value = response.student_id
                }
            } else {
                sessionOverlay.style.display = 'none';
                self.props.afterSessionChange();
            }
        });
    }

    deleteSession() {
        let self = this;

        if (!self.props.allowSessionModification()) return false;

        let data = {
            'parent-cancel-session': 1,
            'student_id': self.props.selectedStudentId.toString(),
            'tutor_id': document.querySelector('input[name=tutor_id]').value,
            'session_id': document.querySelector('input[name=session_id]').value,
            'source': 'members'
        };

        ApiFetch('lms/student-tutor-schedule/', 'DELETE', data).then(function (response) {
            if (response.error) {
                confirmAlert({
                    message: response.error,
                    buttons: [
                        {
                            label: 'Ok'
                        }
                    ]
                });
            } else {
                document.getElementById('add-edit-session').style.display = 'none';
                self.props.afterSessionChange();
            }
        });

        return false;
    }

    updateStatus() {
        let self = this;
        let data = {
            'status': document.querySelector('select[name=status]').value,
            'session_id': document.querySelector('input[name=session_id]').value,
        };

        ApiFetch('lms/session-status/', 'POST', data).then(function (response) {
            if (response.error) {
                confirmAlert({
                    message: response.error,
                    buttons: [
                        {
                            label: 'Ok'
                        }
                    ]
                });
            } else {
                document.getElementById('add-edit-session').style.display = 'none';
                self.props.afterSessionChange();
            }
        });
    }

    render() {
        let self = this;

        return (
            <>
            <div id="add-edit-session" onClick={self.onOverlayClick}>
                <div className="overlay">
                    <div className="exit" onClick={self.hideOverlay}>x</div>
                    {!self.props.isTutor &&
                        <>
                        <h4></h4>
                        <input type="hidden" name="status" value=""/>
                        </>
                    }
                    {self.props.isTutor &&
                    <>
                    <label>Status</label>
                    <select name="status">
                        <option value="scheduled">Scheduled</option>
                        <option value="completed">Completed</option>
                        <option value="missed">Missed</option>
                        <option value="cancelled">Cancelled</option>
                    </select>
                    <button id="update-status" type="submit" className="session-btn" onClick={self.updateStatus}>UPDATE STATUS</button>
                    <p className="read-only">The information below is read-only.</p>
                    </>
                    }
                    <input type="hidden" name="date" value=""/>
                    <input type="hidden" name="session_id" value=""/>
                    <input type="hidden" name="tutor_id" value=""/>
                    <input type="hidden" name="subject_id" value=""/>
                    <div className="time">
                        <label className="">Start Time <span className="timezone"></span></label>
                        <Flatpickr className="mdl-textfield__input" id="start"
                           value={self.props.selectedDate}
                           options={{
                                enableTime: !self.props.isTutor,
                                noCalendar: true,
                                dateFormat: "H:i",
                                altInput: true,
                                altFormat: "h:i K",
                                allowInput: !self.props.isTutor,
                                disabled: self.props.isTutor,
                                minuteIncrement: 15,
                                defaultMinute: 0,
                           }}
                        />
                    </div>
                    {!self.props.isTutor &&
                    <>
                    <div className="session-length">
                        <div>
                            <input type="radio" name="end" id="half-hour-session" value="30" defaultChecked={true}/>
                                <label htmlFor="half-hour-session">1/2 Hour Session</label>
                        </div>
                        <div>
                            <input type="radio" name="end" id="hour-session" value="60"/>
                                <label htmlFor="hour-session">1 Hour Session</label>
                        </div>
                    </div>
                    <div className="subject-container">
                        <label>Subject</label><br/>
                        <select name="subject" id="subject-select"></select>
                    </div>
                    <br/>
                    <button id="schedule-session" type="submit" className="session-btn" onClick={this.saveSession}>SCHEDULE SESSION</button>
                    <button id="delete-session" type="submit" className="session-btn" onClick={this.deleteSession}>CANCEL SESSION</button>
                    <br/>
                    <br/>
                    <a className="back-to-schedule" href="#" onClick={this.hideOverlay}>Back to Calendar</a>
                    <br/>
                    <div className="cancellation-policy">
                        All reschedules and cancellations must occur more than 48 hours before the session starts.
                        Tutoring hours will be credited to your account for cancelled sessions.
                        If you need help please contact online.tutoring@testingmom.com.
                    </div>
                    </>
                    }
                    {self.props.isTutor &&
                    <>
                    <div className="time">
                        <label className="">End Time <span className="timezone"></span></label>
                        <Flatpickr className="mdl-textfield__input" id="end"
                            value={self.props.endTime}
                            options={{
                               enableTime: !self.props.isTutor,
                               noCalendar: true,
                               dateFormat: "H:i",
                               altInput: true,
                               altFormat: "h:i K",
                               allowInput: !self.props.isTutor,
                               disabled: self.props.isTutor,
                               minuteIncrement: 15,
                               defaultMinute: 0,
                           }}
                        />
                    </div>
                    <div className="subject-container">
                        <label>Subject</label><br/>
                        <input disabled name="subject" type="text"/>
                    </div>
                    <br/>
                    <div>
                        <p>Internal Notes: <span
                            className="repeat-description">(Only employees and tutors can see)</span></p>
                        <textarea readOnly name="internal_notes"></textarea>
                    </div>
                    <div>
                        <p>External Notes: <span
                            className="repeat-description">(Employees, tutors, and parents can see)</span></p>
                        <textarea readOnly name="external_notes"></textarea>
                    </div>
                    <br/>
                    <a className="back-to-schedule" href="#" onClick={self.hideOverlay}>Back to Calendar</a>
                    </>
                    }
                </div>
            </div>
            <link rel="stylesheet" href="//cdn.testingmom.com/crm/plugins/flatpicker/css/flatpickr.min.css" />
            </>
        )
    }
}

export default AddSession;