import React, {Component} from 'react';

import StudentSelector from "./StudentSelector";
import {ApiFetch} from "../common/ApiFetch";
import TutoringComponent from "./TutoringComponent";
import MessageTab from "./MessageTab";
import Message from "./Message";

class Inbox extends TutoringComponent {
    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            selected_message: 0,
            selected_student: "",
            students: [],
            student_id: 0,
            tutor_id: 0
        }

        this.messagesLoaded = false;

        this.selectStudent = this.selectStudent.bind(this);
        this.selectMessage = this.selectMessage.bind(this);
        this.loadMessages = this.loadMessages.bind(this);
        this.saveMessage = this.saveMessage.bind(this);
        this.selectMessage = this.selectMessage.bind(this);
        this.afterReplySaved = this.afterReplySaved.bind(this);
        this.resetSelectedMessage = this.resetSelectedMessage.bind(this);
    }

    componentDidMount() {
        let self = this;
        let messages = document.getElementById('messages');
        messages.style.display = 'flex';
        document.querySelector('#tutoring .tm-loading').style.display = 'block';

        ApiFetch('lms/user-tutoring-overview/', 'GET').then(function (results) {
            if (results['tutor']) {
                self.setState({
                   tutor_id: results['tutor']['id']
                });
            }

            self.data_by_student = results['students'];
            if (self.data_by_student) {
                let studentList = [];
                for (let name in self.data_by_student) {
                    if (self.data_by_student.hasOwnProperty(name)) {
                        studentList.push(self.loadNewStudent(name, self.data_by_student[name]['id']));
                    }
                }
                self.setState({
                    students: studentList
                });
                self.selectStudent(Object.keys(self.data_by_student)[0]);
            }
        });

        self.loadMessages(self.props.user.id, 0);
    }

    loadMessages(user_id, tutor_id, student_id) {
        let self = this;
        let endpoint = 'lms/message/?archive=false';
        if (!user_id) {
            user_id = self.props.user.id;
        }
        if (tutor_id) {
            endpoint += '&tutor=' + tutor_id;
        }
        if (student_id) {
            endpoint += '&student=' + student_id;
        }
        let messages = document.getElementById('messages');
        ApiFetch(endpoint, 'GET').then(function(response){
            self.messagesLoaded = true;
            if (messages) {
                document.querySelector('#tutoring .tm-loading').style.display = 'none';
                let fetchedMessages = [];
                if (response && response.results && response.results.length > 0) {
                    document.querySelectorAll('.add-message').forEach(e => e.style.display = 'block');
                    let first_id;
                    for (let r in response.results) {
                        if (response.results.hasOwnProperty(r)) {
                            let s = response.results[r].message;
                            if (!first_id) {
                                first_id = s.id;
                            }
                            s.is_tutor = self.props.user.tutor;
                            let data = s;
                            if (s.from_user && user_id === s.from_user.id) {
                                data.is_users = true;
                                data.name = 'You';
                            } else {
                                data.name = s.from_user.first_name;
                            }
                            if (s.replies) {
                                for (let r in s.replies) {
                                    if (s.replies.hasOwnProperty(r)) {
                                        let reply = s.replies[r];
                                        data.last_reply = reply.id
                                        if (reply.from_user && user_id === reply.from_user.id) {
                                            reply.is_users = true;
                                            reply.name = 'You'
                                            reply.instructor = true;
                                        } else {
                                            reply.is_users = false;
                                            reply.name = reply.from_user.first_name
                                        }
                                        if (!reply.sent_at) {
                                            reply.sent_at = reply.created_at;
                                        }
                                        if (s.replies[r].attachments) {
                                            for (let a in s.replies[r].attachments) {
                                                if (s.replies[r].attachments.hasOwnProperty(a)) {
                                                    s.replies[r].attachments[a].attachment =
                                                        s.replies[r].attachments[a].attachment.replace('tutoring/attachments/', '');
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if (!data.sent_at) {
                                data.sent_at = data.created_at;
                            }
                            if (data.attachments) {
                                for (let a in data.attachments) {
                                    if (data.attachments.hasOwnProperty(a)) {
                                        data.attachments[a].attachment =
                                            data.attachments[a].attachment.replace('tutoring/attachments/', '');
                                    }
                                }
                            }
                            data.message_id = s.id;
                            data.replies = s.replies;
                            data.recipient_id = response.results[r].id;
                            data.reply_length = s.replies.length;
                            fetchedMessages.push(data);
                        }
                    }
                    self.setState({
                        messages: fetchedMessages,
                        selected_message: parseInt(first_id)
                    });
                    self.setMessageButtons(self.resetSelectedMessage);
                    self.setAddFileButton();
                } else {
                    document.getElementById('add-message').style.display = 'block';
                }
            }
        });
    }

    saveMessage(el) {
        let self = this;
        super.saveMessage(el, self.afterReplySaved, self.loadMessages);
        document.querySelector('#tutoring .reply-box.new input[name=subject]').value = '';
    }

    selectMessage(message_id) {
        let self = this;
        self.setState({
            selected_message: parseInt(message_id)
        });
    }

    afterReplySaved(el, data, id) {
        let self = this;

        let bubble_data = {
            'name': 'You',
            'read': true,
            'sent_at': new Date().toISOString(),
            'message': data.message,
            'subject': data.subject,
            'is_users': true,
            'attachments': []
        };

        let updated_messages = [...self.state.messages]
        for (let i in updated_messages) {
            if (updated_messages[i].id === parseInt(data.reply_id)) {
                updated_messages[i].replies.push(bubble_data);
                updated_messages[i].reply_length = updated_messages[i].replies.length;
            }
        }
        self.setState({
            messages: updated_messages
        }, () => {
            document.querySelectorAll('#message-' + data.reply_id + ' .message')
                .forEach(e => e.style.display = 'block');
            self.setDates();
        });
    }

    selectStudent(name) {
        let self = this;
        self.setState({
            selected_student: name,
            student_id: self.data_by_student[name]['id']
        });
    }

    resetSelectedMessage() {
        let self = this;
        self.setState({
           selected_message: 0,
        });
    }

    render() {
        let self = this;

        let tutors = [];
        if (self.state.selected_student in self.data_by_student && "tutors" in self.data_by_student[self.state.selected_student]) {
            tutors = self.data_by_student[self.state.selected_student]["tutors"];
        }
        let showNewMessageBox = (self.messagesLoaded && !self.state.selected_message) ? ' active' : '';

        return (
            <div id="inbox">
                <div className="wrapper wbkg selected-program cont" id="tutoring">
                    <h1>Messages</h1>
                    <div className="loading tm-loading"></div>
                    <div id="messages">
                        <div className="message-tab-list">
                            <div className="message-tab add-message" data-id="new">Add a New Message</div>
                            {self.state.messages.map(function (message) {
                                return(
                                    <MessageTab
                                        key={message.message_id}
                                        messageId={message.message_id}
                                        onMessageSelect={self.selectMessage}
                                        isActive={message.message_id === self.state.selected_message}
                                        onlineClass={message.onlineclass}
                                        startDate={message.start_date}
                                        subject={message.subject}
                                        isTutor={self.props.user.tutor}
                                        tutor={message.tutor}
                                        student={message.student}
                                    />
                                )
                            })}
                        </div>
                        <div className="messages existing">
                            {self.state.messages.map(function(message) {
                                return(
                                    <Message
                                        active={message.message_id === self.state.selected_message}
                                        message_id={message.message_id}
                                        read={message.read}
                                        is_users={message.is_users}
                                        sent_at={message.sent_at}
                                        name={message.name}
                                        subject={message.subject}
                                        message={message.message}
                                        attachments={message.attachments}
                                        replies={message.replies}
                                        reply_length={message.reply_length}
                                        recipient_id={message.recipient_id}
                                        student={message.student}
                                        tutor={message.tutor}
                                        class_id={message.class_id}
                                        key={'message_' + message.message_id}
                                    />
                                )
                            })}
                            <div id="add-message" className={"reply-box new" + showNewMessageBox}>
                                <input type="hidden" className="message_id" value=""/>
                                {self.props.user.tutor &&
                                <>
                                    <StudentSelector
                                        isTutor={self.props.user.tutor}
                                        students={self.state.students}
                                        onStudentSelect={self.selectStudent}
                                        selectedStudent={self.state.selected_student}
                                    />
                                    <input type="hidden" className="student_id" name="student" value={self.state.student_id}/>
                                    <input type="hidden" name="tutor" className="tutor_id" value={self.state.tutor_id}/>
                                </>
                                }
                                {!self.props.user.tutor &&
                                <>
                                    <p>
                                        <label>
                                            Student:
                                            <select className="student_id" name="student" value={self.state.student_id}
                                                    onChange={(e) => self.selectStudent(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)}>
                                                {self.state.students.length > 0 && self.state.students.map(function (student) {
                                                    return (
                                                        <option key={student.id} value={student.id}>{student.name}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </label>
                                    </p>
                                    <p>
                                        <label>
                                            Tutor:
                                            <select name="tutor" className="tutor_id">
                                                {tutors.length > 0 && tutors.map(function (tutor) {
                                                    return (
                                                        <option key={tutor.id} value={tutor.id}>{tutor.name}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </label>
                                    </p>
                                </>
                                }
                                <p><label>
                                    Subject:
                                    <input type="text" name="subject" defaultValue=""/>
                                </label></p>
                                <textarea id="message" name="message" placeholder="Message"></textarea>
                                <p>Attachments:</p>
                                <input type="file" name="attachment[]"/>
                                <a href="#" className="add-file">Add another file</a>
                                <div className="loading tm-loading tiny-tm-loading"></div>
                                <button name="send_message">Send Message</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Inbox;
