import {Component} from 'react';
import moment from "moment-timezone";
import {confirmAlert} from "react-confirm-alert";
import {ApiFetch} from "../common/ApiFetch";
import {API_URL} from "../common/Urls";

class TutoringOverview extends Component {
    constructor(props) {
        super(props);

        this.data_by_student = {};
        this.tz = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];

        this.loadNewStudent = this.loadNewStudent.bind(this);
        this.saveMessage = this.saveMessage.bind(this);
        this.setDates = this.setDates.bind(this);
        this.setMessageButtons = this.setMessageButtons.bind(this);
        this.setAddFileButton = this.setAddFileButton.bind(this);
    }

    saveMessage(el, replyHandler, reloadHandler) {
        let message = el.parentElement.querySelector('textarea').value;
        if (!message || message.length < 3) {
            confirmAlert({
                message: 'Please enter a valid message.',
                buttons: [
                    {
                        label: 'Ok'
                    }
                ]
            });
            return;
        }
        el.style.display = 'none';
        el.parentElement.querySelector('.tiny-tm-loading').style.display = 'block';

        let data = {
            'message': message,
            'subject': el.parentElement.querySelector('input[name=subject]').value,
        }
        let formData = new FormData();
        formData.append('message', message);
        formData.append('subject', el.parentElement.querySelector('input[name=subject]').value);
        formData.append('student_id', el.parentElement.querySelector('.student_id').value);
        formData.append('tutor_id', el.parentElement.querySelector('.tutor_id').value);
        formData.append('api_file_upload', 1);

        let class_id = el.parentNode.querySelector('input.class_id');
        if (class_id) {
            formData.append('class_id', class_id.value);
            data['class_id'] = class_id.value;
        }
        let sessiongroup_id = el.parentNode.querySelector('input.sessiongroup_id');
        if (sessiongroup_id) {
            formData.append('sessiongroup_id', sessiongroup_id.value);
            data['sessiongroup_id'] = sessiongroup_id.value;
        }
        let reply_id = el.parentElement.querySelector('input.reply_id');
        if (reply_id) {
            formData.append('reply_id', reply_id.value);
            data['reply_id'] = reply_id.value;
        }
        let files = el.parentElement.querySelectorAll('input[type=file]');
        let fileUpload = false;
        if (files) {
            for (let file in files) {
                if (files.hasOwnProperty(file)) {
                    if (files[file].files && files[file].files.length) {
                        formData.append("files", files[file].files[0]);
                        fileUpload = true;
                    }
                }
            }
        }

        fetch(API_URL + 'lms/message/',{
                headers: {
                    'Accept': 'application/json',
                },
                method: "POST",
                body: formData,
                mode: 'cors',
                credentials: 'include'
            }
        ).then(function(response) {
            return response.json();
        }).then(function(results) {
            // Reset file input
            files.forEach(e => e.value = null);
            files = Array.from(files);
            while (files.length > 1) {
                files.pop().remove();
            }
            el.parentElement.querySelector('.tiny-tm-loading').style.display = 'none';
            el.style.display = 'block';

            el.parentElement.querySelector('textarea').value = '';
            if (fileUpload || !('reply_id' in data)) {
                reloadHandler();
            } else {
                replyHandler(el, data, results.pk);
            }
        });
    }

    setDates() {
        let self = this;

        let dates = document.querySelectorAll('.dates');
        if (dates) {
            for (let date in dates) {
                if (dates.hasOwnProperty(date)) {
                    let time = moment(dates[date].dataset.date).utc(dates[date].dataset.date);
                    dates[date].innerHTML = time.format('MMMM Do h:mm a ') + self.tz;
                }
            }
        }
    }

    setMessageButtons(addMessageHandler) {
        let self = this;

        let send_messages = document.querySelectorAll('button[name=send_message]');
        send_messages.forEach((oldBtn) => {
            let btn = oldBtn.cloneNode(true);
            oldBtn.parentNode.replaceChild(btn, oldBtn);
            btn.addEventListener('click', function() { //TODO remove listener
                let is_new = btn.innerHTML === 'Send New Message';
                self.saveMessage(btn, is_new);
            });
        });
        document.querySelectorAll('button[name=send_reply]').forEach((old) => {
            let btn = old.cloneNode(true);
            old.parentNode.replaceChild(btn, old);
            btn.addEventListener('click', function(event) { //TODO remove listener
                self.saveMessage(btn);
            });
        });
        document.querySelectorAll('#messages .reply').forEach((old) => {
            let reply = old.cloneNode(true);
            old.parentNode.replaceChild(reply, old);
            reply.addEventListener('click', function(event) { //TODO remove listener
                event.preventDefault();
                reply.parentElement.parentElement.querySelector('.reply-box').style.display = 'block';
            });
        });
        document.querySelectorAll('#messages .show-replies').forEach((old) => {
            let showReply = old.cloneNode(true);
            old.parentNode.replaceChild(showReply, old);
            showReply.addEventListener('click', function(event) { //TODO remove listener
                event.preventDefault();
                showReply.parentNode.parentNode.querySelectorAll('.message')
                    .forEach(e => e.style.display = 'block');
                self.setMessageRead(showReply.dataset.id, true);
            });
        });
        document.querySelectorAll('#messages .hide-message').forEach((old) => {
            let hide = old.cloneNode(true);
            old.parentNode.replaceChild(hide, old);
            hide.addEventListener('click', function(event) { //TODO remove listener
                event.preventDefault();
                let message_id = hide.dataset.id;
                confirmAlert({
                    message: 'Are you sure you want to hide this message?',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: function() {
                                let data = {
                                    archive: 1,
                                    read: 1
                                }
                                ApiFetch('lms/message/' + message_id + '/', 'PATCH', data).then(function (results) {
                                    document.getElementById('tmmessage').style.display = 'none';
                                    self.getTutoringMessages();
                                });
                            }
                        },
                        {
                            label: 'No',
                        }
                    ]
                });
            });
        });
        document.querySelectorAll('.add-message').forEach((old) => {
            let add = old.cloneNode(true);
            old.parentNode.replaceChild(add, old);
            add.addEventListener('click', function(event) { //TODO remove listener
                event.preventDefault();
                if (typeof(addMessageHandler) === "function") {
                    addMessageHandler();
                } else {
                    document.querySelector('.reply-box.new').style.display = 'block';
                }
            });
        });
        document.querySelectorAll('#messages select[name=sessiongroup_id] option').forEach((option) => {
            let time = moment(option.dataset.date).utc(option.dataset.date);
            option.innerHTML = option.innerHTML + time.local().format('MMMM Do h:mm a ') + self.tz;
        });
        self.setDates();
    }

    setAddFileButton() {
        let self = this;
        document.querySelectorAll('.add-file').forEach(e => {
            e.removeEventListener('click', self.addFile);
            e.addEventListener('click', self.addFile); //TODO remove listener
        });
    }

    addFile(event) {
        let btn = event.target.closest('.add-file');
        if (btn) {
            btn.insertAdjacentHTML("beforebegin", '<input type="file" name="attachment[]" />');
        }
        event.preventDefault();
    }

    loadNewStudent(name, id) {
        let self = this;

        // TODO delete once unreads handled
        let other_students = document.querySelector('#other-students');
        if (other_students) {
            // TODO handle unread messages
            // if (unreadMessages && unreadMessages.tutoring.students[id]) {
            //     html += "<span class='new-message'></span>";
            // }
        }

        if (self.data_by_student[name]["grade"]) {
            self.data_by_student[name]["grade"] = self.data_by_student[name]["grade"].split(' ')[0];
            if (self.data_by_student[name]["grade"] === "Kindergarten") {
                self.data_by_student[name]["grade"] = "Kinder";
            } else if (self.data_by_student[name]["grade"] === "2s") {
                self.data_by_student[name]["grade"] = "2s and 3s";
            }
        } else {
            self.data_by_student[name]["grade"] = "";
        }

        return {id: id, name: name, unread: '0'}
    }
}

export default TutoringOverview;