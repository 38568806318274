import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';
import './Printables.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const moment = require('moment-timezone');
const Loading = require('../common/Loading').Loading;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class Printables extends Component {
    constructor(props) {
        super(props);
        this.changeReviewed = this.changeReviewed.bind(this);
        this.togglePrintableTable = this.togglePrintableTable.bind(this);
    }

    changeReviewed(el) {
        if (el.target.checked) {
            let child_id = this.props.child.id;
            let childData = {
                'secure_documents': [el.target.dataset.id]
            };
            el.target.nextSibling.classList.add('clicked');
            this.props.updateChild(child_id, childData);
        } else {
            this.props.deleteDocument(el.target.dataset.doc_id, el.target.dataset.id);
            el.target.nextSibling.classList.remove('clicked');
        }
    }

    togglePrintableTable(el) {
        let table = el.currentTarget.parentElement.parentElement;
        if (table.className === 'open') {
            table.className = 'closed';
        } else {
            table.className = 'open';
        }
    }

    render() {
        let self = this;
        let i = 0;
        let current_grade = self.props.current_grade;
        if (typeof self.props.documents !== "object") {
            return <div style={{position: 'relative', height: '300px'}}><Loading/></div>;
        }
        if (!self.props.documents ||
            !self.props.documents.hasOwnProperty('documents') ||
            Object.keys(self.props.documents.documents).length === 0
        ) {
            return <div style={{position: 'relative', height: '300px'}}><Loading /></div>;
        }
        if (!self.props.documents.documents.hasOwnProperty(self.props.current_grade)) {
            current_grade = '';
        }

        let documents = self.props.documents.documents[current_grade];

        if (Object.entries(self.props.documents).length === 0 && self.props.documents.constructor === Object) {
            return <div style={{position: 'relative', width: '100%', height: '300px'}}><Loading/></div>
        }
        let categories = {};
        if (this.props.categories ||
            (this.props.active_test && this.props.active_test.display_mode.indexOf('category') !== -1)
        ) {
            for (let doc in documents) {
                for (let key in documents[doc]['tests']) {
                    let category = documents[doc]['tests'][key]['category'];
                    if (typeof category !== 'undefined' && category !== null) {
                        if (typeof categories[category['order']] === 'undefined') {
                            categories[category['order']] = {
                                'docs': [],
                                'title': category['title'],
                                'count': 0,
                            }
                        }
                        categories[category['order']]['docs'].push(documents[doc]);
                        categories[category['order']]['count'] += documents[doc]['question_count'];
                    }
                }
            }
        } else {
            categories[0] = {'docs': documents};
        }

        let disabled = "";
        if (!this.props.user.paid_access) {
            disabled = "disabled"
        }

        let firstKey = Object.keys(categories)[0];
        return (
            <div className="printables">
                {Object.keys(categories).length > 0 &&
                Object.keys(categories).map(function (key) {
                    let tableClass = (key === firstKey && self.props.test_number && self.props.test_number === 1) ||
                    (self.props.page && self.props.page === 'state') || !categories[key].hasOwnProperty('title') ?
                        'open' : 'closed';
                    return (
                        <div key={key}>
                            <table className={tableClass}>
                                <thead>
                                {categories[key].hasOwnProperty('title') === true &&
                                <tr onClick={self.togglePrintableTable} className={"category-name"}>
                                    <th colSpan={4}>
                                        <span><strong>{categories[key]['title']}</strong>{
                                            categories[key]['count'] > 0 &&
                                            <span><br/>{categories[key]['count']} Questions</span>
                                        }</span>
                                        <FontAwesomeIcon className={"caret"} icon="caret-down"/>
                                    </th>
                                </tr>
                                }
                                <tr>
                                    <th>Document</th>
                                    {typeof (self.props.child) !== 'undefined' &&
                                    <th>Reviewed Today?</th>
                                    }
                                    {typeof (self.props.child) !== 'undefined' &&
                                    <th>Last Reviewed</th>
                                    }
                                    {typeof (self.props.child) === 'undefined' &&
                                    <th colSpan={2}/>
                                    }
                                    <th>Total Questions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {categories[key]['docs'].map(function (doc) {
                                    let last_reviewed = '';
                                    let checkbox_value = 0;
                                    let now = new Date();
                                    let doc_id = 0;
                                    let sd = {};
                                    if (self.props.child && self.props.child.hasOwnProperty('secure_documents')) {
                                        sd = self.props.child.secure_documents;
                                    }
                                    if (sd.hasOwnProperty(doc.id)) {
                                        last_reviewed = sd[doc.id][sd[doc.id].length - 1]['last_reviewed'];
                                        doc_id = sd[doc.id][sd[doc.id].length - 1]['id'];
                                        let last_reviewed_date = new Date(last_reviewed);
                                        if (last_reviewed_date.getDate() === now.getDate()) {
                                            checkbox_value = 1;
                                        }
                                        last_reviewed = moment.utc(last_reviewed).local().format('MMM. D, YYYY');
                                    } else {
                                        last_reviewed = 'n/a';
                                    }
                                    let check_box_class = (checkbox_value) ? "clicked" : "";
                                    i++;
                                    let link = '';
                                    if (self.props.user.paid_access) {
                                        let child_id = self.props.appProps.match.params.child_id;
                                        let path = '/test-prep/document/' + doc.id;
                                        if (child_id !== undefined) {
                                            path = '/dashboard/' + child_id +'/document/' + doc.id
                                        }
                                        link = <Link
                                            to={path}>
                                            <FontAwesomeIcon icon={'external-link-alt'} className="external-link" />
                                            {doc.title}
                                        </Link>
                                    } else {
                                        link = <a href={WEBSITE_URL + 'buy-now/?redirected=1'}>
                                            <FontAwesomeIcon icon={'external-link-alt'} className="external-link" />
                                            {doc.title}
                                        </a>
                                    }
                                    let questionCount = doc.question_count;
                                    if (doc.question_count === 0) {
                                        questionCount = ""
                                    }
                                    return (
                                        <tr key={i}>
                                            <td>
                                                {link}
                                            </td>
                                            {typeof (self.props.child) !== 'undefined' &&
                                            <td className={disabled}>
                                                <label className="switch" htmlFor={"doc-" + doc.id}>
                                                    <input
                                                        type="checkbox"
                                                        id={"doc-" + doc.id}
                                                        checked={checkbox_value}
                                                        value={checkbox_value}
                                                        onChange={self.changeReviewed}
                                                        data-id={doc.id}
                                                        data-doc_id={doc_id}
                                                    />
                                                    <span className={check_box_class + " slider round"}>
                                                        <span className="yes yes-no">Yes</span>
                                                        <span className="no yes-no">No</span>
                                                    </span>
                                                </label>
                                            </td>
                                            }
                                            {typeof (self.props.child) !== 'undefined' &&
                                            <td className="date">{last_reviewed}</td>
                                            }
                                            {typeof (self.props.child) === 'undefined' && <td colSpan={2}/> }
                                            <td>{questionCount}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    )
                })}
                {Object.keys(categories).length === 0 && <div style={{height: '300px', position: 'relative'}}><Loading/></div>}
            </div>
        )
    }
}

export default withRouter(Printables);
