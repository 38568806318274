import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import './SBA.css'
import ThumbnailLink from "../common/ThumbnailLink";

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Loading = require('../common/Loading').Loading;
const WEBSITE_URL = require('../common/Urls').WEBSITE_URL;

class SbaIndex extends Component {
    constructor(props) {
        super(props);
        let grade = "";
        if (this.props.user.children.length && this.props.user.children[this.props.user.active_child] !== undefined) {
            grade = this.props.user.children[this.props.user.active_child]['grade']
        }
        var urlParams = new URLSearchParams(window.location.search);
        let param = urlParams.get('grade');
        if (param) {
            if (param === 'all') {
                grade = "";
            } else {
                grade = param
            }
        }

        this.state = {
            'sbas': [],
            'selected_grade': grade
        };
        this.filter = this.filter.bind(this);
    }

    componentDidMount()
    {
        let self = this;
        ApiFetch('sba/program/?limit=100&status=1&ordering=program_name').then(function(response) {
            if (response.hasOwnProperty('results') && response['results'].length) {
                self.setState({
                    'sbas': response['results']
                });
            }
        });
    }

    filter(e)
    {
        this.setState({
            selected_grade:  parseInt(e.target.value)
        });
    }

    render () {
        let self = this;
        let i = 0;
        let user_sbas = [];
        if (this.props.user.sbas) {
            for (let sba in this.props.user.sbas) {
                if (this.props.user.sbas.hasOwnProperty(sba)) {
                    user_sbas.push(this.props.user.sbas[sba].program.id);
                }
            }
        }

        if (typeof(this.props.grades) !== 'undefined' && Object.keys(this.props.grades).length > 0) {
            return (
                <div id="skill-building" className={'sba content-container'}>
                    <div className="dashboard">
                        <h1>Your Interactive Learning Apps</h1>
                        <div className={'watch'} onClick={function (e){self.props.extraProps.showSiteTour(e, 'sba');}}>Watch SBA Guide</div>
                        <div className="subjects left">
                            <div className={'styled-select'}>
                            <select name="grade" onChange={this.filter} defaultValue={self.state.selected_grade}>
                                <option disabled>Filter by Grade Level...</option>
                                <option>All Grades</option>
                                {
                                    Object.keys(this.props.grades).map(function(grade) {
                                        i++;
                                        if (self.props.grades[grade].name === '2s and 3s') {
                                            return false;
                                        }
                                        return <option value={self.props.grades[grade]['id']}
                                                       key={i}>{self.props.grades[grade].name}</option>;
                                    })
                                }
                            </select>
                            </div>
                        </div>
                        <div className="key right">
                            <div className="star-key">
                                <div className="star orange"/>
                                <span>Free Immediate Access</span>
                            </div>
                            <div className="star-key">
                                <div className="star green"/>
                                <span>All programs included FREE with your membership - over $500 value!</span>
                            </div>
                            <div className="star-key">
                                <div className="star blue"/>
                                <span>Access Granted</span>
                            </div>
                        </div>
                        <div className="clear" />
                        <div className="programs">
                            {this.state.sbas.length === 0 && <div style={{padding: '100px 0', width: '100%', position: 'relative'}}><Loading/></div>}
                            {
                                this.state.sbas.map(function(sba){
                                    i++;
                                    let safeDisplayName = <span dangerouslySetInnerHTML={{__html: sba.program_name}}/>;
                                    let sbaUrl = "skill-building-academy/" + sba.url;
                                    let user_access = false;
                                    if (user_sbas.indexOf(sba['id']) !== -1) {
                                        user_access = true;
                                    }
                                    for (let grades in sba.grades) {
                                        let grade = '';
                                        if (sba['grades'].length > 0) {
                                            grade = sba['grades'][0]['name'];
                                            if (sba['grades'].length > 1) {
                                                grade += ' - ' + sba['grades'][sba['grades'].length - 1]['name'];
                                            }
                                        }
                                        let link = '';
                                        if (self.props.user.paid_access) {
                                            link = sbaUrl;
                                        } else {
                                            link = WEBSITE_URL + 'buy-now/?redirected=1';
                                        }
                                        if (sba.grades.hasOwnProperty(grades) && sba.grades[grades]['id'] === self.state.selected_grade) {
                                            return <ThumbnailLink
                                                key={i}
                                                link={link}
                                                alt={sba.display_name}
                                                thumbnail={sba.thumbnail}
                                                title={sba.tooltip_text}
                                                display_name={safeDisplayName}
                                                instant_access={sba.instant_access}
                                                grade_val={self.state.selected_grade}
                                                description={sba.tooltip_text}
                                                grade={grade}
                                                user_access={user_access}
                                            />
                                        } else if (!self.state.selected_grade) {
                                            return <ThumbnailLink
                                                key={i}
                                                link={link}
                                                alt={sba.display_name}
                                                thumbnail={sba.thumbnail}
                                                title={sba.tooltip_text}
                                                display_name={safeDisplayName}
                                                instant_access={sba.instant_access}
                                                grade_val={self.state.selected_grade}
                                                description={sba.tooltip_text}
                                                grade={grade}
                                                user_access={user_access}
                                            />
                                        }
                                    }
                                    return ''
                                })
                            }
                        </div>
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }
}

export default withRouter(SbaIndex);
