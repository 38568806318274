import React, {Component} from 'react';
import './Assessment.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loading } from "../common/Loading";
import DeviceOrientation, { Orientation } from '../common/DeviceOrientation';
import Results from './Results'
import ReportedError from './ReportedError';
import { confirmAlert } from "react-confirm-alert";

const ApiFetch = require('../common/ApiFetch').ApiFetch;
const Question = require('./Question').Question;
const SetAnswerArea = require('./Question').SetAnswerArea;

class Assessment extends Component {
    constructor(props) {
        super(props);

        let grades = {};
        for (let g in this.props.grades) {
            if (this.props.grades.hasOwnProperty(g)) {
                grades[this.props.grades[g].id] = this.props.grades[g].numeric;
            }
        }
        this.state = {
            answers: {},
            answerCount: 1,
            correctCount: 0,
            currentQuestion: 0,
            finished: false,
            grade: this.props.user.children[this.props.user.active_child].grade,
            grades_by_id: grades,
            gradeAudio: {},
            questions: [],
            learnosityQuestions: [],
            learnosityConfigAttempts: 0,
            reference: '',
            results: [],
            show_results: false,
            totalCount: 0
        };
        this.audio_players = [];
        this.session_id = null;
        this.activity_id = null;

        this.can_play_audio = false;
        this.learnosity = false;
        this.questionsLoaded = false;
        this.correct = this.correct.bind(this);
        this.closeAssessment = this.closeAssessment.bind(this);
        this.finishGame = this.finishGame.bind(this);
        this.goToResults = this.goToResults.bind(this);
        this.learnosityReady = this.learnosityReady.bind(this);
        this.learnosityNextQuestion = this.learnosityNextQuestion.bind(this);
        this.learnosityValid = this.learnosityValid.bind(this);
        this.replay = this.replay.bind(this);
        this.wrong = this.wrong.bind(this);
        window.addEventListener('orientationchange', function(){
            SetAnswerArea('questions')
        });
        window.addEventListener('resize', function(){
            SetAnswerArea('questions')
        });
    }

    showReportedError() {
        let re = document.getElementById('reportedError');
        if (re) {
            re.style.display = 'block';
        }
    }

    get_time_elapsed(start, end) {
        var elapsed_ms = new Date(end) - new Date(start);
        if (elapsed_ms) {
            return Math.round(elapsed_ms / 1000);
        } else {
            return 0;
        }
    };

    learnosityReady()
    {
        let self = this;
        if (self.props.user.is_staff) {
            console.log('ready 2');
        }
        self.startTime = new Date();
        let items = self.itemsApp.getItems();
        let item_id = 0;
        self.audio_players = [];
        let overlays = document.getElementsByClassName('overlay');
        if (overlays) {
            for (let o in overlays) {
                if (overlays.hasOwnProperty(o)) {
                    overlays[o].style.display = 'none';
                }
            }
        }
        Object.values(items).forEach(function(item) {
            for (let feature in item['features']) {
                if (item['features'].hasOwnProperty(feature) && item['features'][feature]['type'] === 'audioplayer') {
                    let f = self.itemsApp.feature(item['features'][feature]['feature_id']);
                    if ((!self.props.use_audio || !self.state.gradeAudio[self.state.grade]) && f !== undefined) {
                        f.destroy();
                    } else {
                        self.audio_players[item_id] = item['features'][feature]['feature_id'];
                    }
                }
            }

            let totalQuestions = item['questions'].length;
            let is_shorttext = false;
            let is_multi_click = false;
            for (let q in item['questions']) {
                if (item['questions'][q].type === 'shorttext') {
                    is_shorttext = true;
                }
                if (item['questions'][q].validation.valid_response.score > 1) {
                    is_multi_click = true;
                }
            }

            if (totalQuestions === 1 && !is_shorttext && !is_multi_click) {
                for (let q in item['questions']) {
                    let question = self.itemsApp.question(item['questions'][q]['response_id']);
                    question.on("changed", function () {
                        let resultData = {
                            'question': item['reference'],
                            'correct': 0,
                            'total_questions': 1
                        };
                        if (this.isValid()) {
                            self.state.correctCount += 1;
                            resultData['correct'] = 1;
                        }
                        self.state.results.push(resultData);
                        self.state.totalCount += 1;
                        self.learnosityNextQuestion(this, false);
                    });
                }
            } else {
                let ques = document.getElementById('ques-' + item_id);
                let button = document.createElement('button');
                button.classList.add('continue');
                button.innerHTML = 'Continue <i class="fas fa-play"></i>';
                ques.appendChild(button);
                let cont = ques.querySelector('.continue');

                if(cont) {
                    cont.addEventListener('click', function () {
                        self.learnosityValid(item);
                    });
                }

                if (is_shorttext) {
                    ques.querySelector('input[type=text]').on('keypress', function(e){
                        if (e.which === 13) {
                            self.learnosityValid(item);
                        }
                    });
                }
            }

            item_id++;
        });

        if (self.audio_players[0]) {
            self.itemsApp.feature(self.audio_players[0]).audio.play();
        }
    }

    learnosityValid(item) {
        let self = this;
        let attempted = 0;
        let resultData = {
            'question': item['reference'],
            'correct': 0,
            'total_questions': 1
        };
        for (let q in item['questions']) {
            let question = self.itemsApp.question(item['questions'][q]['response_id']);
            if (question.isAttempted()) {
                attempted += 1;
            } else {
                continue;
            }
            if (question.isValid()) {
                resultData['correct'] += 1;
            }
        }

        if (attempted === item['questions'].length) {
            self.state.totalCount += 1;
            if (resultData['correct'] === item['questions'].length) {
                self.state.correctCount += 1;
                resultData['correct'] = 1;
            } else {
                resultData['correct'] = 0;
            }
            self.state.results.push(resultData);
            self.learnosityNextQuestion();
        } else {
            confirmAlert({
                title: 'Sorry!',
                message: "Please answer all questions to continue.",
                buttons: [
                    {
                        label: 'Ok',
                    }
                ]
            });
        }
    }

    learnosityNextQuestion() {
        let self = this;
        let cur_ques = document.getElementById('ques-' + self.state.currentQuestion);
        if (cur_ques) {
            cur_ques.style.display = 'none';
        }
        if (self.audio_players[self.state.currentQuestion]) {
            self.itemsApp.feature(self.audio_players[self.state.currentQuestion]).audio.stop();
        }
        self.state.currentQuestion++;
        self.props.resetReportedError();
        self.itemsApp.save();
        self.setState({reference: self.state.learnosityQuestions[self.state.currentQuestion]});
        if (self.state.currentQuestion !== parseInt(self.props.number_of_questions)) {
            let nextQuestion = document.getElementById('ques-' + self.state.currentQuestion);
            if (nextQuestion) {
                nextQuestion.style.display = 'block';
            }
            if (self.audio_players.hasOwnProperty(self.state.currentQuestion)) {
                self.itemsApp.feature(self.audio_players[self.state.currentQuestion]).audio.play();
            }
        } else {
            document.getElementById('questions').innerHTML = '<h2>Please wait while we gather your results.</h2>';
            self.itemsApp.submit({
                'success': function () {
                    let data = {
                        questions: self.state.results,
                        child: self.props.user.children[self.props.user.active_child].id,
                        test: self.props.user.active_test.id,
                        grade: self.state.grade,
                        total_questions: self.state.learnosityQuestions.length,
                        total_correct: self.state.correctCount,
                        learnosity: true,
                        session_id: self.session_id,
                        activity_id: self.activity_id
                    };
                    let path = 'user-child-assessment/';
                    if (typeof self.props.skill_id !== 'undefined') {
                        path = 'user-child-practice/';
                    }
                    ApiFetch(path, 'POST', data).then(function(res) {
                        let child = self.props.user.children[self.props.user.active_child];
                        child['assessments'].push(res);
                        child['assessments_by_test'] = self.props.setChildAssessmentsByTest(child['assessments']);
                        let user = self.props.user;
                        user['children'][self.props.active_child] = child;
                        self.props.updateUser(user, function() {
                            self.props.history.push('/dashboard/' + self.props.user.active_child);
                            self.props.setSkills(true, self.itemsApp);
                        });
                    });
                }
            });
        }
    }

    getQuestions()
    {
        let self = this;
        if (this.props.user.active_test &&
            !this.questionsLoaded &&
            this.state.grade &&
            Object.keys(this.props.dt_config).length
        ) {
            this.questionsLoaded = true;
            let gradeAudio = {};
            if (this.props.user.active_test &&
                this.props.dt_config.hasOwnProperty(this.props.user.active_test.id) &&
                this.props.dt_config[this.props.user.active_test.id].hasOwnProperty(this.state.grade)
            ) {
                self.learnosity = this.props.dt_config[this.props.user.active_test.id][this.state.grade]['learnosity'];
            }
            for (let i in this.props.dt_config[this.props.user.active_test.id]) {
                gradeAudio[i] = this.props.dt_config[this.props.user.active_test.id][i]['use_audio'];
            }

            let screener = document.querySelector('.result-links .cogat select[name=screener]');
            let skills = '';

            if (screener && screener.value === 'screener') {
                skills = '283,286,498,508';
            }

            if (self.learnosity) {
                let path = 'learnosity/app/item/?test=' + self.props.user.active_test.id;
                path += '&grade=' + self.state.grade;
                path += '&child=' + self.props.user.children[self.props.user.active_child].id;
                path += '&limit=' + self.props.number_of_questions;
                if (typeof self.props.skill_id !== 'undefined') {
                    path += '&skill=' + self.props.skill_id;
                }
                if (skills) {
                    path += '&skill=' + skills;
                }
                ApiFetch(path).then(function(res2) {
                    if (self.props.user.is_staff) {
                        console.log(res2);
                        console.log(window.LearnosityItems);
                    }
                    self.setState({
                        learnosityQuestions: res2.request.items,
                        gradeAudio: gradeAudio,
                        reference: res2.request.items[self.state.currentQuestion]
                    }, function(){
                        if (self.props.user.is_staff) {
                            console.log(res2);
                            console.log(window.LearnosityItems);
                        }
                        self.session_id = res2.request.session_id;
                        self.activity_id = res2.request.activity_id;
                        self.itemsApp = window.LearnosityItems.init(res2['init'], '#learnosity_assess', {
                            errorListener: function (e) {
                                // Adds a listener to all error codes.
                                console.log("Error Code ", e.code);
                                console.log("Error Message ", e.msg);
                                if (self.state.learnosityConfigAttempts < 3) {
                                    self.questionsLoaded = false;
                                    self.setState({
                                        learnosityConfigAttempts: self.state.learnosityConfigAttempts + 1
                                    }, self.getQuestions)
                                }
                            },
                            readyListener: function () {
                                if (self.props.user.is_staff) {
                                    console.log('ready 1');
                                }
                                self.setState({
                                    learnosityConfigAttempts: 0
                                }, self.learnosityReady)
                            }
                        });
                        if (self.props.user.is_staff) {
                            console.log(self.itemsApp);
                        }
                    });
                });
            } else {
                let child = self.props.user.children[self.props.user.active_child];
                let result_ids = [];
                if (child.hasOwnProperty(('assessments_by_test')) &&
                    child.assessments_by_test.hasOwnProperty(self.props.user.active_test.id)) {
                    result_ids = child.assessments_by_test[self.props.user.active_test.id]['ids'];
                }
                ApiFetch('digital-tutor/test-question-ids/?tm7=1&result=' + result_ids.join(','), 'GET').then(
                    function (res2) {
                        let tests = [self.props.user.active_test.taxonomy];
                        let test_id = self.props.user.active_test.id;
                        if (self.props.dt_config.hasOwnProperty(test_id) &&
                            self.props.dt_config[test_id].hasOwnProperty(self.state.grade)) {
                            tests = [];
                            for (let test in self.props.dt_config[test_id][self.state.grade]['tests']) {
                                tests.push(self.props.dt_config[test_id][self.state.grade]['tests'][test]['pk']);
                            }
                        }
                        let data = {
                            'grade': self.state.grades_by_id[self.state.grade],
                            'limit': self.props.number_of_questions,
                            'random': 1,
                            'status': 1,
                            'app': 346,
                            'test': tests.join(','),
                            'tm7': 1
                        };

                        if (res2) {
                            data['exclude'] = res2.join(',');
                        }
                        let queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
                        if (typeof self.props.skill_id !== 'undefined') {
                            queryString += '&skill=' + self.props.skill_id;
                        } else if(skills) {
                            queryString += '&skill=' + skills;
                        } else {
                            queryString += '&skill=' + self.props.user.active_test.skills.join(',')
                        }
                        ApiFetch('question/?' + queryString, 'GET').then(function (res3) {
                            if (res3.count < self.props.number_of_questions) {
                                delete data['exclude'];
                                let queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
                                if (typeof self.props.skill_id !== 'undefined') {
                                    queryString += '&skill=' + self.props.skill_id;
                                } else {
                                    queryString += '&skill=' + self.props.user.active_test.skills.join(',')
                                }
                                ApiFetch('question/?' + queryString, 'GET').then(function (res4) {
                                    self.installGame(res4, gradeAudio);
                                    self.startTime = new Date();
                                });
                            } else {
                                self.installGame(res3, gradeAudio);
                                self.starttime = new Date();
                            }
                        });
                    }
                );
            }
        }
    }

    installGame(results, gradeAudio) {
        this.setState({
            questions: results['results'],
            gradeAudio: gradeAudio
        }, function() {
            SetAnswerArea('questions');
        });
    }

    componentDidMount() {
        if (this.props.history.action === 'POP') {
            this.props.history.push(this.props.history.location.pathname.replace('/practice', ''));
            return;
        }
        if (typeof this.props.user.active_test === 'object') {
            this.getQuestions();
        }
    }

    componentWillReceiveProps(props)
    {
        if (typeof props.user.active_test === 'object') {
            this.getQuestions();
        }
    }

    getCookie(name) {
        let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    setCookie(name, value, days) {
        let d = new Date();
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    correct(e)
    {
        let self = this;
        let question = document.getElementById('ques-' + this.state.currentQuestion);
        let correct_answers = question.getElementsByClassName('correct-answer');
        if (correct_answers.length > this.state.answerCount) {
            correct_answers[this.state.answerCount - 1].style.display = 'none';
            correct_answers[this.state.answerCount].style.display = 'block';
            this.setState({
                answerCount: this.state.answerCount + 1,
            });
        } else {
            let dims = e.target.getBoundingClientRect();
            let posx = e.clientX - dims.left;
            let posy = e.clientY - dims.top;
            this.state.results.push({
                'question': question.dataset.id,
                'correct': true,
                'answer_posx': (posx / dims.width) * 100,
                'answer_posy': (posy / dims.height) * 100,
            });
            if (this.state.currentQuestion < this.state.questions.length - 1) {
                this.setState({
                    currentQuestion: this.state.currentQuestion + 1,
                    correctCount: this.state.correctCount + 1,
                }, function(){
                    self.nextQuestion(question);
                });
            } else {
                this.setState({
                    currentQuestion: this.state.currentQuestion + 1,
                    correctCount: this.state.correctCount + 1,
                }, self.finishGame);
            }
        }
    }

    wrong(e)
    {
        let self = this;
        let question = document.getElementById('ques-' + this.state.currentQuestion);
        let dims = e.target.getBoundingClientRect();
        let posx = e.clientX - dims.left;
        let posy = e.clientY - dims.top;
        this.state.results.push({
            'question': question.dataset.id,
            'correct': false,
            'answer_posx': (posx / dims.width) * 100,
            'answer_posy': (posy / dims.height) * 100,
        });
        this.setState({
            currentQuestion: this.state.currentQuestion + 1
        }, function() {
            if (self.state.currentQuestion < self.state.questions.length) {
                self.nextQuestion(question);
            } else {
                self.finishGame();
            }
        });
    }

    nextQuestion(question)
    {
        question.style.display = 'none';
        let current_audio = question.getElementsByTagName('audio');
        if (current_audio.length) {
            current_audio[0].pause();
            current_audio[0].currentTime = 0;
        }
        let next = document.getElementById('ques-' + (this.state.currentQuestion));
        next.style.display = 'block';
        let audio = next.getElementsByTagName('audio');
        if (audio.length) {
            audio[0].play();
        }
        SetAnswerArea('questions');
        this.props.resetReportedError();
    }

    startGame()
    {
        let starts = document.getElementsByClassName('start');
        if (starts.length) {
            starts[0].style.display = 'none';
        }
        let overlays = document.getElementsByClassName('overlay');
        if (overlays.length) {
            overlays[0].style.display = 'none';
        }
        let question = document.getElementById('ques-0');
        let audio = question.getElementsByTagName('audio');
        if (audio.length) {
            audio[0].play();
        }
        SetAnswerArea('questions');
    }

    finishGame() {
        let self = this;
        let current_audio = document.getElementById('ques-' + (this.state.currentQuestion - 1)).getElementsByTagName('audio');
        if (current_audio.length) {
            current_audio[0].pause();
            current_audio[0].currentTime = 0;
        }
        self.setState({
            finished: true
        }, function(){
            let overlays = document.getElementsByClassName('overlay');
            if (overlays.length) {
                overlays[0].style.display = 'block';
            }
        });
        let path = 'user-child-assessment/';
        if (typeof this.props.skill_id !== 'undefined') {
            path = 'user-child-practice/';
        }
        for (let q in self.state.questions) {
            if (self.state.questions.hasOwnProperty(q)) {
                let skills = [];
                for (let n in self.state.results) {
                    if (parseInt(self.state.results[n]['question']) === parseInt(self.state.questions[q].id)) {
                        for (let t in self.state.questions[q].taxonomies) {
                            if (
                                self.state.questions[q].taxonomies.hasOwnProperty(t) &&
                                self.state.questions[q].taxonomies[t].taxonomy === 'skill'
                            ) {
                                skills.push(self.state.questions[q].taxonomies[t].id);
                            }
                        }
                        self.state.results[n]['skills'] = skills;
                    }
                }
            }
        }

        let data = {
            questions: this.state.results,
            child: this.props.user.children[this.props.user.active_child].id,
            test: this.props.user.active_test.id,
            grade: this.state.grade,
            total_questions: this.state.questions.length,
            total_correct: this.state.correctCount,
            audio: this.can_play_audio,
            session_id: this.session_id,
            activity_id: this.activity_id
        };
        ApiFetch(path, 'POST', data).then(function(res) {
            let child = self.props.user.children[self.props.user.active_child];
            child['assessments'].push(res);
            child['assessments_by_test'] = self.props.setChildAssessmentsByTest(child['assessments']);
            let user = self.props.user;
            user['children'][self.props.user.active_child] = child;
            self.props.updateUser(user, function() {self.props.setSkills(true)});
            self.setState({
                show_results: true
            });
        });
        this.props.resetReportedError();
    }

    closeAssessment() {
        let self = this;
        if (this.state.learnosityQuestions.length > 0 && self.audio_players[self.state.currentQuestion]) {
            self.itemsApp.feature(self.audio_players[self.state.currentQuestion]).audio.stop();
        }
        this.props.setSkills(true);
        this.props.history.goBack();
    }

    replay()
    {
        this.learnosity = false;
        this.questionsLoaded = false;
        this.setState({
            answers: {},
            answerCount: 1,
            correctCount: 0,
            currentQuestion: 0,
            finished: false,
            grade: this.props.user.children[this.props.user.active_child].grade,
            gradeAudio: {},
            questions: [],
            learnosityQuestions: [],
            results: [],
            show_results: false,
        }, this.getQuestions);
    }

    goToResults()
    {
        this.props.history.push('/dashboard/' +  this.props.user.active_child);
    }

    replayAudio(key) {
        let audio = document.getElementById("question_audio" + key);
        audio.pause();
        audio.currentTime = 0;
        audio.play();
    }

    hideMessage() {
        let overlays = document.getElementsByClassName('message-overlay');
        if (overlays.length){
            overlays[0].style.display = 'none';
        }
    }

    render () {
        let self = this;
        let i = -1;
        for (let grade in this.props.grades) {
            if (this.props.use_audio &&
                this.props.grades.hasOwnProperty(grade) &&
                this.props.grades[grade]['id'] === this.props.user.children[this.props.user.active_child].grade &&
                (this.state.gradeAudio[this.props.grades[grade]['id']] === true ||
                    (this.state.gradeAudio[this.props.grades[grade]['id'] - 1]) === true)
            ) {
                this.can_play_audio = true;
            }
        }

        let show_warning = false;
        if (parseInt(this.getCookie('dt-warning')) !== 1) {
            this.setCookie('dt-warning', 1, 14);
            show_warning = true;
        }
        let learnosity = this.state.learnosityQuestions.length > 0;

        let question_id = 0;
        if (this.state.questions.length > 0 && this.state.questions.hasOwnProperty(this.state.currentQuestion)) {
            question_id = this.state.questions[this.state.currentQuestion].id;
        }
        if (self.learnosity && !window.LearnosityItems) {
            confirmAlert({
                title: 'Sorry!',
                message: "There is an issue connecting to our practice questions server please refresh and " +
                    "try again. If the issue persists please contact support by using" +
                    " the options on the lower right.",
                buttons: [
                    {
                        label: 'Refresh Now',
                        onClick: function() {
                            window.location.reload();
                        }
                    },
                    {
                        label: 'Continue',
                    }
                ]
            });
            return false;
        }
        return (
            <div id="assessment" className="assessment">
                <DeviceOrientation lockOrientation={'landscape'}>
                    <Orientation orientation='landscape' alwaysRender={true}>
                        {this.state.show_results === false &&
                        <div id="questions" className="questions">
                            <div className='container'>
                                <FontAwesomeIcon icon={"times-circle"} className={"close"}
                                                 onClick={this.closeAssessment}/>
                                {this.state.questions.length > 0 && this.state.questions.map(function (question) {
                                    i++;
                                    return (
                                        <Question
                                            key={i}
                                            id={i}
                                            ans_image={question.ans_image}
                                            answerArea={question.answerArea}
                                            audio={question.audio}
                                            can_play_audio={self.can_play_audio}
                                            correct={self.correct}
                                            gradeAudio={self.state.gradeAudio}
                                            image={question.image}
                                            question={question.question}
                                            question_id={question.id}
                                            skills={question.taxonomies}
                                            replayAudio={self.replayAudio}
                                            reportError={self.showReportedError}
                                            test={self.props.user.active_test.id}
                                            wrong={self.wrong}
                                        />
                                    )
                                })}
                                {this.state.learnosityQuestions.length > 0 && this.state.learnosityQuestions.map(function (question) {
                                    i++;
                                    return (<div key={i} id={"ques-" + i} className="ques-cont learnosity">
                                        <div data-reference={question} className={' learnosity-item'}/>
                                        <div className={'desc'}>
                                            <div className="report-error" onClick={self.showReportedError}>
                                                Something wrong with this question? Click here to notify us.
                                            </div>
                                        </div>
                                    </div>);
                                })
                                }
                            </div>
                            <div className="overlay">
                                {show_warning &&
                                <div className="message-overlay warning">
                                    <p><FontAwesomeIcon icon={"exclamation-triangle"}/>Important Message</p>
                                    <p>To help your child increase his or her attention and focus, some questions use
                                        the <strong>same
                                            artwork</strong> but the <strong>questions are different.</strong></p>
                                    <p>Please make sure your child plays close attention to each question.</p>
                                    <button onClick={this.hideMessage}>Okay, got it!</button>
                                </div>
                                }
                                {this.state.finished === true && <Loading/>}
                                {this.state.questions.length === 0 && <Loading/>}
                                {this.state.questions.length > 0 && !this.state.finished &&
                                <div className="message-overlay start">
                                    <section>
                                        <h2 className="green-heading">General Instructions</h2>
                                        <p>Read each question carefully and select an answer.
                                            Note that you cannot move forward or backwards in the practice.</p>
                                    </section>
                                    <p onClick={this.startGame}>Start</p>
                                </div>
                                }
                            </div>
                        </div>
                        }
                        {this.state.show_results === true &&
                        <Results
                            correct={this.state.correctCount}
                            goToResults={this.goToResults}
                            replay={this.replay}
                            total={this.props.number_of_questions}
                        />
                        }
                    </Orientation>
                    <Orientation orientation='portrait' alwaysRender={false}>
                        <div className="overlay">
                        <div className="message-overlay" style={{textAlign: 'center'}}>
                            <FontAwesomeIcon icon={'sync-alt'} style={{fontSize: '40px'}}/>
                            <br/><br/>
                            <p>Practice works best in landscape mode please rotate your device.</p>
                        </div>
                        </div>                            
                    </Orientation>
                </DeviceOrientation>
                <ReportedError
                    learnosity={learnosity}
                    reference={self.state.reference}
                    question_id={question_id}
                />
            </div>
        )
    }
}

export default Assessment;
