import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const moment = require('moment-timezone');


class Session extends Component {
    render() {
        if (this.props.empty) {
            return (
                <li className='session empty'>
                    <div className='session-date'>
                        <p className='day'>{this.props.selectedMoment.format("ddd")}</p>
                        <p className='date'>{this.props.selectedMoment.format("MMM DD")}</p>
                    </div>
                    <div className='session-info'>
                        <div className='session-title'>No Sessions Scheduled</div>
                    </div>
                </li>
            )
        }

        let btnState = 0;
        let timeTill = "";
        let btnActive = "";

        let endMoment = moment(this.props.info.end_time);
        let startMoment = moment(this.props.start);

        if (endMoment.diff(this.props.current_time, 'minutes', true) < 0) {
            btnState = -1;
        } else if (startMoment.diff(this.props.current_time, 'days') === 0) {
            btnState = 1;
            timeTill = moment.utc(startMoment.diff(this.props.current_time)).format("HH:mm:ss");
            if (startMoment.diff(this.props.current_time, 'minutes', true) < 5) {
                btnState = 2;
                btnActive = " active";
                if (startMoment.diff(this.props.current_time, 'minutes', true) < 0 && endMoment.diff(this.props.current_time, 'minutes', true) > 0) {
                    timeTill = "00:00:00";
                }
            }
        }

        let link;
        if (btnState === 2) {
            link = this.props.info.tutor.zoom_link;
        }

        return (
            <li className='session'>
                {this.props.first &&
                <div className='session-date'>
                    <p className='day'>{this.props.selectedMoment.format("ddd")}</p>
                    <p className='date'>{this.props.selectedMoment.format("MMM DD")}</p>
                </div>
                }
                {!this.props.first &&
                <div className='session-date'></div>
                }
                <div className='session-info'>
                    <div className='session-title'>
                        <p className='time'>{this.props.start.toLocaleTimeString('en-us', { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })}</p>
                        <p className='title'>{this.props.info.subject.name + " Tutoring with " + this.props.displayName}</p>
                    </div>
                    {btnState > 0 &&
                    <div className='join'>
                        <div className='time-till '>
                            <FontAwesomeIcon icon='clock' className='far fa-clock'/>
                            <p>{timeTill}</p>
                        </div>
                        <a data-link={this.props.info.tutor.zoom_link} href={link}>
                            <div className={'join-btn' + btnActive}>Join Session</div>
                        </a>
                    </div>
                    }
                    {btnState === 0 &&
                    <div className='join'>
                        <div className='time-till edit-time-till'>
                            <Link to={"/tutoring/calendar?date=" + this.props.start.toLocaleDateString('fr-CA')
                                + "&tid=" + this.props.info.tutor.id}>
                                <FontAwesomeIcon icon='pen' className='fas fa-pen'/>
                            </Link>
                        </div>
                    </div>
                    }
                </div>
            </li>
        )
    }
}

export default Session;