import React, {Component} from 'react';

class TutorTabs extends Component {
    constructor(props) {
        super(props);

        this.setActiveTutor = this.setActiveTutor.bind(this);
    }

    setActiveTutor(e) {
        let self = this;
        let tutorList = document.querySelectorAll('#tutoring .tutor-tab');
        for (let t in tutorList) {
            if (tutorList.hasOwnProperty(t)) {
                tutorList[t].classList.remove('active');
            }
        }

        let tutorEle = e.target.closest('.tutor-tab');
        tutorEle.classList.add('active');
        self.props.onTutorSelect(tutorEle.dataset.id);
    }

    render() {
        let self = this;

        return (
            <ul className="tutor-tabs">
                {self.props.tutors.length > 0 && self.props.tutors.map(function (tutor) {
                    let isActive = (tutor.id === self.props.selectedTutor) ? "active" : "";
                    return (
                        <li key={tutor.id} className={"tutor-tab " + isActive} data-id={tutor.id} onClick={self.setActiveTutor}>
                            {tutor.name}<span></span></li>
                    )
                })
                }
            </ul>
        )
    }
}

export default TutorTabs;