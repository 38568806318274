import React, {Component} from 'react';
import MessageBubble from "./MessageBubble";

class Message extends Component {
    render() {
        let isActive = (this.props.active) ? ' active' : '';
        let student_id = this.props.student ? this.props.student.id : '';
        let tutor_id = this.props.tutor ? this.props.tutor.id : '';
        return (
            <div className={"message-container" + isActive} id={"message-" + this.props.message_id}>
                <div className="container">
                    <MessageBubble
                        id={this.props.message_id}
                        read={this.props.read}
                        isUsers={this.props.is_users}
                        sentAt={this.props.sent_at}
                        name={this.props.name}
                        subject={this.props.subject}
                        message={this.props.message}
                        attachments={this.props.attachments}
                        key={'bubble_' + this.props.message_id}
                    />
                    {this.props.replies.length > 0 && this.props.replies.map(function (reply) {
                        return(
                            <MessageBubble
                                id={reply.id}
                                read={reply.read}
                                isUsers={reply.is_users}
                                sentAt={reply.sent_at}
                                name={reply.name}
                                subject={reply.subject}
                                message={reply.message}
                                attachments={reply.attachments}
                                key={'bubble_' + reply.id}
                            />
                        )
                    })
                    }
                </div>
                {this.props.showControls &&
                    <p className="controls">
                        <a className="reply" href="#" data-id={this.props.message_id}>Reply to Message</a>
                        <span> - </span>
                        <a className="show-replies" href="#" data-id={this.props.message_id}>
                            {this.props.reply_length > 0 && 'Show Replies (' + this.props.reply_length + ')'}
                            {this.props.reply_length <= 0 && 'No Replies'}</a>
                        <span> - </span>
                        <a className="hide-message" href="#" data-id={this.props.recipient_id}>Hide Message</a>
                    </p>
                }
                <div className="reply-box">
                    <input type="hidden" className="message_id" value={this.props.message_id}/>
                    <input type="hidden" className="reply_id" value={this.props.message_id}/>
                    <input type="hidden" className="student_id" value={student_id}/>
                    <input type="hidden" className="tutor_id" value={tutor_id}/>
                    <input type="hidden" name="subject" value=""/>
                    <textarea id="message" name="message" placeholder="Message" />
                    <p>Attachments:</p>
                    <input type="file" name="attachment[]" />
                    <a href="#" className="add-file">Add another file</a>
                    <div className="loading tm-loading tiny-tm-loading" />
                    <button name="send_message" value={this.props.class_id}>Send Message</button>
                </div>
            </div>
        );
    }
}

export default Message;