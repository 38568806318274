import React, {Component} from 'react';

class MessageBubble extends Component {
    render() {
        let className = "message";
        if (this.props.read) {
            className += " recent-message";
        }
        if (this.props.isUsers) {
            className += " self";
        }
        return (
            <div className={className} data-id={this.props.id}>
                <p className="message-info">
                    <span className="dates" data-date={this.props.sentAt}></span> | From: {this.props.name}
                </p>
                <p className="message-subject section-title">{this.props.subject}</p>
                <p className="message-body">{this.props.message}</p>
                {this.props.attachments.length > 0 && this.props.attachments.map(function (attachment) {
                    return (
                        <p className="message-attachment" key={attachment.attachment}>
                            <a href={"https://cdn.testingmom.com/tutoring/attachments/" + attachment.attachment}
                              download>{attachment.attachment}</a>
                        </p>
                    )
                })
                }
            </div>
        )
    }
}

export default MessageBubble;